import React from "react"
import {
  Document, Page, Image, StyleSheet,
} from "@react-pdf/renderer"

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  image: {
    width: 500, // Adjust as needed
    height: 400, // Adjust as needed
  },
})

const PyramidPDF = ({ imageURL }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {imageURL && <Image src={imageURL} style={styles.image} />}
    </Page>
  </Document>
)

export default PyramidPDF
