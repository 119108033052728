import React, {
  useEffect, useState, useRef, useCallback,
} from "react"
import ReactDOMServer from "react-dom/server"
import _, { debounce } from "underscore"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { Modal, Divider, Button } from "antd"
import html2canvas from "html2canvas"
import pdfSvg from "../../../assets/images/pdf-file.svg"
import PieChart from "../charts/PieChart"
// import TChart from "../charts/TChart";
import {
  uaDarkBlue, uaGold, uaRed, uaTeal,
} from "../../enums"
import TypeToggle from "./TypeToggle"
import {
  // filterByGradeLevel,
  infoAlert,
  filterByGradeLevelInCategories,
  filterByGradeLevel,
  getFilteredSelStandards,
  getFilteredConnectionType,
  filterByCategorySelReport,
} from "../../utilities"
import ProgressBar from "../charts/ProgressBar"
import commonStrings from "../../commonStrings"
import infoIcon from "../../../assets/images/info_icon.png"

import Gauge from "../SELQualityGauge"
import HorizontalBarChart from "../charts/HorizontalBar"
import BarChart from "../charts/SELQualityBarChart"
import SELSchoolCapacity from "./SELSchoolCapacity"
import SELQualitypdf from "./SelQualityPDF"
import FormItemSelectCell from "./FormItemSelectCell"
import CommonColors from "../../commonColors"
import { graphNamesArray } from "./ProgramMatrixObjects"
import dottedLoader from "../../../assets/images/dottedloader.gif"

function ProgramMatrixSELQuality({
  matrix,
  submissionsCount,
  schoolsCount,
  currentSchool,
}) {
  const [getElementById, setGetElementById] = useState(null)
  const [type, setType] = useState("student")
  const [typeForStudentCapacity, setTypeForStudentCapacity] = useState("reinforces")
  const [matrixData, setMatrixData] = useState({})
  const [graphUrls, setGraphUrls] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const isCapturing = useRef(false)

  const [gradeLabels, setGradeLabels] = useState([])
  const [gradeDatasets, setGradeDatasets] = useState([])
  const [filterSelectedValue, setFilterSelectedValue] = useState("")
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalVisibleforFilter, setIsModalVisibleforFilter] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [modalTitle, setModalTitle] = useState("")
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false)
  const [currentSchoolActivities, setCurrentSchoolActivities] = useState([])

  useEffect(() => {
    setCurrentSchoolActivities(
      filterByCategorySelReport(window.gon.CURRENT_SCHOOL_ACTIVITIES, type),
    )
  }, [window.gon.CURRENT_SCHOOL_ACTIVITIES, type])

  const fixedGradeLabels = [
    "PreK",
    "K",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ]
  const elementIds = [
    "pdfContent1",
    "pdfContent2",
    "pdfContent3",
    "pdfContent4",
    "pdfContent5",
    "pdfContent6",
    "pdfContent7",
    "pdfContent8",
    "pdfContent9",
  ]

  useEffect(() => {
    $("[data-toggle=\"tooltip\"]").tooltip("dispose").tooltip()
  }, [type])

  const percent = (a, b) => `${Math.round((a / b) * 100)}%`

  const standards = _.memoize(() => {
    const hash = {}
    _.pluck(window.gon.SEL_STANDARDS, "standards").forEach((array) => {
      _.extend(hash, array)
    })
    return hash
  })

  const r = matrix.quality_report[type]
  const activitiesCount = r.items.length

  const p = percent

  const universalSupportKeys = getFilteredSelStandards(
    currentSchoolActivities,
    filterSelectedValue,
  )

  const column1Items = universalSupportKeys.map((key) => (
    <>
      <b>{key}</b>:{" "}
      {type === "student"
        ? standards()[key].student_indicator_language
        : standards()[key].adult_indicator_language}
    </>
  ))
  const column2Items = _.difference(
    Object.keys(standards()),
    universalSupportKeys,
  ).map((key) => (
    <>
      <b>{key}</b>:{" "}
      {type === "student"
        ? standards()[key].student_indicator_language
        : standards()[key].adult_indicator_language}
    </>
  ))

  const infoAlertItemList = (items) => (
    <ul className="text-left custom-scrollbar text-left-scrollar">
      {items.map((item, i) => (
        <li key={i} className="mb-2 leading-tight">
          {item}
        </li>
      ))}
    </ul>
  )
  const infoAlertItemListGradeLevel = (items) => (
    <ul className="text-left custom-scrollbar text-left-scrollar">
      {items.map((item, i) => (
        <li key={i} className="mb-2 leading-tight">
          {item.activity}
        </li>
      ))}
    </ul>
  )

  const showModal = (title, content) => {
    setModalTitle(title)
    setModalContent(content)
    setIsModalVisible(true)
  }

  const getProgramMatrix = () => {
    APIClient.get("/program_matrix/current_program_matrix_get", (response) => {
      if (response) {
        setMatrixData(response)
      }
    })
  }

  useEffect(() => {
    getProgramMatrix()
  }, [])

  const closeModal = () => {
    setIsModalVisible(false)
  }
  const modaltitleStyle = {
    fontSize: "21px",
    fontWeight: "700",
    paddingTop: "10px",
    display: "flex",
    justifyContent: "center",
  }
  const handleIndicatorsWithUniversalSupportClick = (index) => {
    const items = index === 0 ? column1Items : column2Items

    const title = index === 0 ? (
      <span style={modaltitleStyle}>
        Indicators <u style={{ padding: "0 5px" }}>with</u> Universal Supports
      </span>
    ) : (
      <span style={modaltitleStyle}>
        Indicators<u style={{ padding: "0 5px" }}>without</u>Universal
        Supports
      </span>
    )

    showModal(
      title,
      ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    )
  }

  const handleActivitiesWithDataClick = (index) => {
    const title = index === 0 ? (
      <span style={modaltitleStyle}>
        Activities <u style={{ padding: "0 5px" }}>with</u> Data
      </span>
    ) : (
      <span style={modaltitleStyle}>
        Activities<u style={{ padding: "0 5px" }}>without</u> Data
      </span>
    )

    const items = (
      filterSelectedValue
        ? filterByGradeLevel(r.items, filterSelectedValue)
        : r.items
    )
      .filter((i) => (index === 0 ? !!i.data_collection : !i.data_collection))
      .map((i) => i.activity)

    if (items.length > 0) {
      showModal(
        title,
        ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
      )
    }
  }

  const handlePromoteReinforceClick = (index) => {
    const title = index === 0
      ? "Indicators that Promote SEL"
      : "Indicators that Reinforce SEL"

    const array = index === 0
      ? getFilteredConnectionType(
        currentSchoolActivities,
        filterSelectedValue,
        "promotes",
      )
      : getFilteredConnectionType(
        currentSchoolActivities,
        filterSelectedValue,
        "reinforces",
      )

    const items = array.map((key) => (
      <>
        <b>{key}</b>:{" "}
        {type === "student"
          ? standards()[key].student_indicator_language
          : standards()[key].adult_indicator_language}
      </>
    ))
    showModal(
      <span className="title-style">{title}</span>,
      ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    )
  }

  const handleTierOfSupportClick = (index) => {
    const tier = ["Universal", "Targeted", "Tertiary"][index]
    const title = `${tier} Activities`
    const items = (
      filterSelectedValue
        ? filterByGradeLevel(
          r[`items_with_${tier.toLowerCase()}_support`],
          filterSelectedValue,
        )
        : r[`items_with_${tier.toLowerCase()}_support`]
    ).map((i) => i.activity)

    showModal(
      <span className="title-style">{title}</span>,
      ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    )
  }

  const handleActivitiesCountClick = (index) => {
    const key = Object.keys(
      filterSelectedValue
        ? filterByGradeLevelInCategories(
          r.activities_by_competency,
          filterSelectedValue,
        )
        : r.activities_by_competency,
    )[index]
    const items = (
      filterSelectedValue
        ? filterByGradeLevelInCategories(
          r.activities_by_competency,
          filterSelectedValue,
        )
        : r.activities_by_competency
    )[key].map((i) => i.activity)
    const styledTitle = <span style={modaltitleStyle}>{key} Activities</span>
    showModal(
      styledTitle,
      ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    )
  }
  const handleDomainActivityCountClick = (index) => {
    const title = index === 0
      ? "Schools with at least 5 activities per domain"
      : "Schools without at least 5 activities per domain"
    const items = r.schools_by_domain_activity_count[index].map((i) => i.name)
    infoAlert({
      title,
      html: ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    })
  }

  const handleActivitiesByDESSAClick = (index) => {
    const key = Object.keys(
      filterSelectedValue
        ? filterByGradeLevelInCategories(
          r.activities_by_dessa,
          filterSelectedValue,
        )
        : r.activities_by_dessa,
    )[index]
    const items = (
      filterSelectedValue
        ? filterByGradeLevelInCategories(
          r.activities_by_dessa,
          filterSelectedValue,
        )
        : r.activities_by_dessa
    )[key].map((i) => i.activity)

    const styledTitle = (
      <span style={modaltitleStyle}>
        {key} {commonStrings.selActivities}
      </span>
    )
    showModal(
      styledTitle,
      ReactDOMServer.renderToStaticMarkup(infoAlertItemList(items)),
    )
  }
  const studentHasData = matrix.quality_report.student.items.length > 0
  const adultHasData = matrix.quality_report.adult.items.length > 0
  useEffect(() => {
    if (!studentHasData && adultHasData) {
      setType("adult")
    }
  }, [studentHasData, adultHasData])

  useEffect(() => {
    const activities = matrixData.items

    if (!_.isEmpty(matrixData)) {
      const gradeLevelCounts = new Array(fixedGradeLabels.length).fill(0)

      activities.forEach((activity) => {
        if (!_.isEmpty(activity.sel_standards)) {
          activity.internal_selected_grades.forEach((gradeObject) => {
            const { grade } = gradeObject
            const labelIndex = fixedGradeLabels.findIndex(
              (label) => label.toLowerCase() === grade.toLowerCase(),
            )
            if (labelIndex !== -1) {
              gradeLevelCounts[labelIndex] += 1
            }
          })
        }
      })

      setGradeLabels(fixedGradeLabels)
      setGradeDatasets([
        {
          label: commonStrings.noOfactivities,
          data: gradeLevelCounts,
          backgroundColor: CommonColors.UA_GRAPH_BACKGROUND,
        },
      ])
    }
  }, [matrixData])

  const currentschoolLogo = currentSchool && currentSchool.logo && currentSchool.logo.url
    ? currentSchool.logo.url
    : null

  const openFilterInfoModal = () => {
    const styledTitle = (
      <span style={modaltitleStyle}>{commonStrings.followingGraphs}</span>
    )
    const graphNames = (
      <div className="text-left custom-scrollbar text-left-scrollar">
        {graphNamesArray.map((value) => (
          <div className="graph-points">{`• ${value}`}</div>
        ))}
      </div>
    )
    showModal(styledTitle, ReactDOMServer.renderToStaticMarkup(graphNames))
  }

  const debouncedCaptureElements = useCallback(
    debounce(async () => {
      if (isCapturing.current) return
      isCapturing.current = true
      setIsLoading(true)

      const captureElement = async (element) => {
        if (element) {
          try {
            const canvas = await html2canvas(element, { scale: 2 })
            return canvas.toDataURL("image/png")
          } catch (error) {
            console.error("Error capturing element:", error)
          }
        }
        return null
      }

      const capturedUrls = await Promise.all(
        Object.values(getElementById).map((element) => captureElement(element)),
      )

      const newGraphUrls = capturedUrls.reduce((acc, url, index) => {
        if (url) acc[`graphUrlBase${64 + index}`] = url
        return acc
      }, {})

      setGraphUrls(newGraphUrls)
      setIsLoading(false)
      setIsGeneratingPDF(false)

      isCapturing.current = false
    }, 100),
    [getElementById],
  )
  useEffect(() => {
    setIsLoading(true)
    setIsGeneratingPDF(false)

    const timeout = setTimeout(() => {
      const elements = elementIds.reduce((acc, id) => {
        acc[id] = document.getElementById(id)
        return acc
      }, {})

      setGetElementById(
        Object.fromEntries(
          Object.entries(elements).filter(([_, value]) => value !== null), // eslint-disable-line no-unused-vars
        ),
      )
    }, 500)

    return () => clearTimeout(timeout)
  }, [type, typeForStudentCapacity, filterSelectedValue])

  useEffect(() => {
    setFilterSelectedValue("")
  }, [type])

  useEffect(() => {
    if (
      getElementById
      && Object.keys(getElementById).length
      && isGeneratingPDF
    ) {
      debouncedCaptureElements()
    }
  }, [getElementById, isGeneratingPDF])
  const showModals = () => {
    setIsModalVisibleforFilter(true)
    setIsLoading(true)
    setIsGeneratingPDF(true)
  }
  const handleCancel = () => {
    setIsModalVisibleforFilter(false)
    setIsGeneratingPDF(false)
    setIsLoading(false)
  }

  const filtersApplied = filterSelectedValue
    ? Object.values(filterSelectedValue).some(
      (filterArray) => filterArray.length > 0,
    )
    : false

  return (
    <div>
      <div className="pdf-activity-details">
        <button
          className="styling-img"
          onClick={showModals}
          aria-label="Download PDF"
        >
          <img src={pdfSvg} alt="Download PDF" style={{ width: "40px" }} />
        </button>
        <Modal
          title={null}
          visible={isModalVisibleforFilter}
          onCancel={handleCancel}
          footer={null}
          centered
          bodyStyle={{ textAlign: "center", padding: "30px" }}
          className="activity-details-modal"
        >
          {isLoading ? (
            <div className="pdf-loader-section">
              <img
                src={dottedLoader}
                alt="Loading..."
                className="pdf-loader-icon"
              />
              <span className="pdf-preparing-text">
                {commonStrings.pdfPreparing}
              </span>
              <span className="pdf-waiting-text">{commonStrings.waiting}</span>
              <div className="pdf-action-buttons">
                <Button onClick={handleCancel} className="cancel-button">
                  {commonStrings.cancel}
                </Button>
                <Button disabled className="download-button disabled">
                  {commonStrings.download}
                </Button>
              </div>
            </div>
          ) : isGeneratingPDF ? (
            <div className="pdf-generation-container">
              <div className="pdf-loader-section">
                <img
                  src={dottedLoader}
                  alt="Loading..."
                  className="pdf-loader-icon"
                />
                <span className="pdf-preparing-text">
                  {commonStrings.pdfPreparing}
                </span>
                <span className="pdf-waiting-text">
                  {commonStrings.waiting}
                </span>
                <div className="pdf-action-buttons">
                  <Button onClick={handleCancel} className="cancel-button">
                    {commonStrings.cancel}
                  </Button>
                  <Button disabled className="download-button disabled">
                    {commonStrings.download}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="pdf-ready-container">
              <div className="pdf-info-section">
                <img src={pdfSvg} alt="PDF Icon" className="pdf-icon" />
                <span className="pdf-ready-text">
                  {filtersApplied && type !== "adult"
                    ? commonStrings.pdfReadyselQuality
                    : "PDF is ready to download"}
                </span>
                {filtersApplied && type !== "adult" && (
                  <div className="selected-filters-list">
                    <span className="filter-item-sel-quality">
                      {commonStrings.gradeLevelTitleFilter}{" "}
                      {filterSelectedValue}
                    </span>
                  </div>
                )}
              </div>
              <div className="pdf-action-buttons">
                <Button onClick={handleCancel} className="cancel-button">
                  {commonStrings.cancel}
                </Button>

                <PDFDownloadLink
                  document={(
                    <SELQualitypdf
                      graphUrls={graphUrls}
                      currentschoolLogo={currentschoolLogo}
                      filterSelectedValue={filterSelectedValue}
                      filtersApplied={filtersApplied}
                      type={type}
                    />
                  )}
                  fileName={`${type}_SEL_quality_report.pdf`}
                >
                  {({ loading }) => (
                    <Button className="download-button">
                      {loading || isLoading ? "Loading..." : "Download"}
                    </Button>
                  )}
                </PDFDownloadLink>
              </div>
            </div>
          )}
        </Modal>
      </div>

      <div className="">
        {/* {matrix.quality_report.adult.items.length > 0 && (
          <div className="group py-4 Student-adult-toggle-buttons">
            <TypeToggle type={type} setType={setType} />
          </div>
        )} */}
        {studentHasData && adultHasData && (
          <div className="group py-4 Student-adult-toggle-buttons">
            <TypeToggle type={type} setType={setType} />
          </div>
        )}

        {type === "student" && (
          <div style={{ backgroundColor: "white" }} id="pdfContent9">
            <Divider>
              <span className="categories-heading">
                {commonStrings.activityToGradeLevel}
              </span>
            </Divider>
            <div style={{ width: "100%", backgroundColor: "white" }}>
              <div className="mb-3" style={{ backgroundColor: "white" }}>
                <BarChart
                  labels={gradeLabels}
                  datasets={gradeDatasets}
                  labelX="- Grade Level -"
                  labelY="- Number of Activities -"
                  stepSize={1}
                  type="student"
                  graphType="activity-grade"
                  onClick={(e) => {
                    const activities = matrixData.items
                    const filteredActivities = activities.filter(
                      (value) => !_.isEmpty(value.sel_standards),
                    )
                    const activitiesWithOutAdult = filteredActivities.filter(
                      (value) => value.category !== "Adult SEL",
                    )
                    const filteredGrade = activitiesWithOutAdult.filter(
                      (item) => item.internal_selected_grades.some(
                        (g) => g.grade === e.label,
                      ),
                    )

                    showModal(
                      <span style={modaltitleStyle}>
                        {`Activities with Grade: ${e.label}`}
                      </span>,
                      ReactDOMServer.renderToStaticMarkup(
                        infoAlertItemListGradeLevel(filteredGrade),
                      ),
                    )
                  }}
                  isUpdated
                />
              </div>
            </div>
          </div>
        )}

        {type === "student" && (
          <div>
            <div className="d-flex align-items-center">
              <FormItemSelectCell
                allowClear
                label="Grade Level Filter"
                name="grade"
                values={fixedGradeLabels}
                placeholder="Select a grade"
                handleOnChangeSelect={(key, val) => {
                  console.log("🚀 ~ val:", val)
                  setFilterSelectedValue(key)
                }}
                colDimensions={{ md: 8, lg: 8 }}
              />
              <div className="info-icon">
                <span onClick={openFilterInfoModal}>
                  <img
                    style={{ width: "40px", cursor: "pointer" }}
                    src={infoIcon}
                  />
                </span>
              </div>
            </div>
          </div>
        )}
        {submissionsCount && (
          <div className="px-4 py-4">
            <div className="row">
              <div className="col-6">
                <h4>{commonStrings.schoolWithMatrix}</h4>
                <ProgressBar
                  progress={(submissionsCount / schoolsCount) * 100}
                  tooltip={`${submissionsCount} of ${schoolsCount} schools have submitted program matrices this school year`}
                  tooltipPlacement="bottom"
                />
              </div>
              <div className="col-6">
                <h4>Schools with at least 5 activities in each domain</h4>
                <ProgressBar
                  progress={
                    (r.schools_by_domain_activity_count[0].length
                      / submissionsCount)
                    * 100
                  }
                  tooltip={`${r.schools_by_domain_activity_count[0].length} of ${submissionsCount} schools`}
                  tooltipPlacement="bottom"
                  onClick={handleDomainActivityCountClick}
                />
              </div>
            </div>
          </div>
        )}
        <div
          id="pdfContent1"
          className="group px-4 py-4 SEL_quality_background"
        >
          <div className="row">
            <div
              className="gauge-bars"
              style={{
                justifyContent: type === "adult" ? "end" : "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={`col-4 ${type === "adult" ? "offset-md-4" : null}`}
              >
                {!submissionsCount && (
                  <>
                    <Gauge
                      progress={
                        type === "adult"
                          ? p(
                            r.standards_with_universal_support.length,
                            r.indicators_count,
                          )
                          : p(
                            getFilteredSelStandards(
                              currentSchoolActivities,
                              filterSelectedValue,
                            ).length,
                            r.indicators_count,
                          )
                      }
                      title="Matrix"
                      onGaugeClick={handleIndicatorsWithUniversalSupportClick}
                      obtained={
                        type === "adult"
                          ? r.standards_with_universal_support.length
                          : getFilteredSelStandards(
                            currentSchoolActivities,
                            filterSelectedValue,
                          ).length
                      }
                      type={type}
                    />
                    <div className="main-activity-with_data">
                      <div className="Activities_with_data">
                        <span>{commonStrings.universalSupportIndicators}</span>
                      </div>

                      <div className="items_with_data_styling">
                        {type === "student" && (
                          <span
                            className={type === "adult" ? "adult-color" : ""}
                          >
                            {
                              getFilteredSelStandards(
                                currentSchoolActivities,
                                filterSelectedValue,
                              ).length
                            }{" "}
                            / {r.indicators_count}&nbsp;Indicators
                          </span>
                        )}
                        {type === "adult" && (
                          <span
                            className={type === "adult" ? "adult-color" : ""}
                          >
                            {r.standards_with_universal_support.length} /{" "}
                            {r.indicators_count}&nbsp;Indicators
                          </span>
                        )}
                      </div>

                      <div className="activitiesCount_styling">
                        <span className={type === "adult" ? "adult-color" : ""}>
                          {type === "adult"
                            && p(
                              r.standards_with_universal_support.length,
                              r.indicators_count,
                            )}

                          {type === "student"
                            && p(
                              getFilteredSelStandards(
                                currentSchoolActivities,
                                filterSelectedValue,
                              ).length,
                              r.indicators_count,
                            )}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                {submissionsCount && (
                  <>
                    <h4>{commonStrings.percentageUniversal}</h4>
                    <PieChart
                      labels={["0-25%", "25-50%", "50-75%", "75-100%"]}
                      colors={[uaRed, uaGold, uaDarkBlue, uaTeal]}
                      values={r.schools_with_universal_support}
                      tooltipSuffix=" schools"
                      legend
                    />
                  </>
                )}
              </div>
              {(type === "student" || type === "adult") && (
                <div className="col-8">
                  <div className="ratio-Indicator-header">
                    <div className="ratio-Indicator">
                      <span>{commonStrings.ratioIndicator}: </span>
                    </div>
                    <div className="standards_that_promote_percentage">
                      {type === "student" && (
                        <span className={type === "adult" ? "adult-color" : ""}>
                          {p(
                            getFilteredConnectionType(
                              currentSchoolActivities,
                              filterSelectedValue,
                              "promotes",
                            ).length,
                            r.standards_count,
                          )}{" "}
                          :{" "}
                          {p(
                            getFilteredConnectionType(
                              currentSchoolActivities,
                              filterSelectedValue,
                              "reinforces",
                            ).length,
                            r.standards_count,
                          )}
                        </span>
                      )}
                      {type === "adult" && (
                        <span className={type === "adult" ? "adult-color" : ""}>
                          {p(
                            r.standards_that_promote.length,
                            r.standards_count,
                          )}{" "}
                          :{" "}
                          {p(
                            r.standards_that_reinforce.length,
                            r.standards_count,
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="main-actual-vs-goals">
                    <div className="actual-vs-goals">
                      <span className={type === "adult" ? "adult-color" : ""}>
                        {commonStrings.actual}
                      </span>
                    </div>
                    <div className="vs-actual-goals">
                      <span>{commonStrings.vs}</span>
                    </div>
                    <div className="goal-vs-actual">
                      <span> {commonStrings.goal}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <HorizontalBarChart
                        labels={[
                          "Indicators that Promote SEL",
                          "Indicators that Reinforce SEL",
                        ]}
                        labelStyles={{ cursor: "default" }}
                        values={
                          type === "student"
                            ? [
                              p(
                                getFilteredConnectionType(
                                  currentSchoolActivities,
                                  filterSelectedValue,
                                  "promotes",
                                ).length,
                                r.standards_count,
                              ),
                              p(
                                getFilteredConnectionType(
                                  currentSchoolActivities,
                                  filterSelectedValue,
                                  "reinforces",
                                ).length,
                                r.standards_count,
                              ),
                            ]
                            : [
                              p(
                                r.standards_that_promote.length,
                                r.standards_count,
                              ),
                              p(
                                r.standards_that_reinforce.length,
                                r.standards_count,
                              ),
                            ]
                        }
                        goals={[60, 40]}
                        onClick={handlePromoteReinforceClick}
                        type={type}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!submissionsCount && (
          <div className="group px-4 py-4 SEL_quality_background">
            {/* <TChart
              column1Title="Universally supported indicators"
              column2Title="Indicators not yet with universal support"
              column1Items={column1Items}
              column2Items={column2Items}
            /> */}
          </div>
        )}

        <div
          id="pdfContent2"
          className="group px-4 py-4 SEL_quality_background"
        >
          <div
            className="row"
            style={{
              width: "100%",

              justifyContent: "end",
            }}
          >
            <div className="active-count">
              <div
                className="col-10"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Gauge
                  obtained={
                    filterSelectedValue
                      ? filterByGradeLevel(
                        r.items_with_data,
                        filterSelectedValue,
                      ).length
                      : r.items_with_data.length
                  }
                  // progress={p(
                  //   filterSelectedValue
                  //     ? filterByGradeLevel(
                  //         r.items_with_data,
                  //         filterSelectedValue
                  //       ).length
                  //     : r.items_with_data.length,
                  //   activitiesCount
                  // )}
                  progress={
                    p(
                      filterSelectedValue
                        ? filterByGradeLevel(
                          r.items_with_data,
                          filterSelectedValue,
                        ).length
                        : r.items_with_data.length,
                      filterSelectedValue
                        ? filterByGradeLevel(r.items, filterSelectedValue)
                          .length
                        : r.items.length,
                    ) === "NaN%"
                      ? 0
                      : p(
                        filterSelectedValue
                          ? filterByGradeLevel(
                            r.items_with_data,
                            filterSelectedValue,
                          ).length
                          : r.items_with_data.length,
                        filterSelectedValue
                          ? filterByGradeLevel(r.items, filterSelectedValue)
                            .length
                          : r.items.length,
                      )
                  }
                  total={activitiesCount}
                  title="Matrix"
                  onGaugeClick={handleActivitiesWithDataClick}
                  type={type}
                />
                <div className="main-container-activity-with_data">
                  <div className="inner-Activities_with_data">
                    <span>{commonStrings.activitiesData}</span>
                  </div>
                  <div className="inner-items_with_data_styling">
                    <span className={type === "adult" ? "adult-color" : ""}>
                      {filterSelectedValue
                        ? filterByGradeLevel(
                          r.items_with_data,
                          filterSelectedValue,
                        ).length
                        : r.items_with_data.length}{" "}
                      /{" "}
                      {filterSelectedValue
                        ? filterByGradeLevel(r.items, filterSelectedValue)
                          .length
                        : r.items.length}{" "}
                      Activities
                    </span>
                  </div>
                  <div className="activitiesCount-number-styling">
                    <span className={type === "adult" ? "adult-color" : ""}>
                      {(
                        filterSelectedValue
                          ? filterByGradeLevel(r.items, filterSelectedValue)
                            .length > 0
                          : r.items.length > 0
                      )
                        ? p(
                          filterSelectedValue
                            ? filterByGradeLevel(
                              r.items_with_data,
                              filterSelectedValue,
                            ).length
                            : r.items_with_data.length,
                          filterSelectedValue
                            ? filterByGradeLevel(r.items, filterSelectedValue)
                              .length
                            : r.items.length,
                        )
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-8" style={{ width: "100%" }}>
              <div className="standards_that_promote-header">
                <div className="standards_that_promote">
                  <span>
                    {commonStrings.RatioOfUniversalTargetedTertiary}:{" "}
                  </span>
                </div>
                <div className="standards_that_promote_percentage-length">
                  <span className={type === "adult" ? "adult-color" : ""}>
                    {p(
                      filterSelectedValue
                        ? filterByGradeLevel(
                          r.items_with_universal_support,
                          filterSelectedValue,
                        ).length
                        : r.items_with_universal_support.length,
                      activitiesCount,
                    )}
                    :
                    {p(
                      filterSelectedValue
                        ? filterByGradeLevel(
                          r.items_with_targeted_support,
                          filterSelectedValue,
                        ).length
                        : r.items_with_targeted_support.length,
                      activitiesCount,
                    )}
                    :
                    {p(
                      filterSelectedValue
                        ? filterByGradeLevel(
                          r.items_with_tertiary_support,
                          filterSelectedValue,
                        ).length
                        : r.items_with_tertiary_support.length,
                      activitiesCount,
                    )}
                  </span>
                </div>
              </div>
              <div className="main-actual-vs-goals">
                <div className="actual-vs-goals">
                  <span className={type === "adult" ? "adult-color" : ""}>
                    {commonStrings.actual}
                  </span>
                </div>
                <div className="vs-actual-goals">
                  <span>{commonStrings.vs}</span>
                </div>
                <div className="goal-vs-actual">
                  <span> {commonStrings.goal}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-12" style={{ width: "100%" }}>
                  <HorizontalBarChart
                    labels={[
                      "Universal Activities",
                      "Targeted Activities",
                      "Tertiary Activities",
                    ]}
                    values={[
                      p(
                        filterSelectedValue
                          ? filterByGradeLevel(
                            r.items_with_universal_support,
                            filterSelectedValue,
                          ).length
                          : r.items_with_universal_support.length,
                        activitiesCount,
                      ),
                      p(
                        filterSelectedValue
                          ? filterByGradeLevel(
                            r.items_with_targeted_support,
                            filterSelectedValue,
                          ).length
                          : r.items_with_targeted_support.length,
                        activitiesCount,
                      ),
                      p(
                        filterSelectedValue
                          ? filterByGradeLevel(
                            r.items_with_tertiary_support,
                            filterSelectedValue,
                          ).length
                          : r.items_with_tertiary_support.length,
                        activitiesCount,
                      ),
                    ]}
                    goals={[80, 15, 5]}
                    onClick={handleTierOfSupportClick}
                    type={type}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="pdfContent3"
          className="group px-4 py-4 SEL_quality_background"
        >
          <div className="row">
            <div className="col-6">
              <BarChart
                labels={_.keys(r.activities_by_competency)}
                datasets={[
                  {
                    label: "activities",
                    data: _.values(
                      filterSelectedValue
                        ? filterByGradeLevelInCategories(
                          r.activities_by_competency,
                          filterSelectedValue,
                        )
                        : r.activities_by_competency,
                    ).map((a) => a.length),
                    backgroundColor: `rgba(${uaTeal}, 0.75)`,
                  },
                ]}
                stepSize={1}
                onClick={handleActivitiesCountClick}
                type={type}
              />
              <div className="activities_count">
                <span>{commonStrings.activitiesCount}</span>
              </div>
            </div>
            <div className="col-6">
              <BarChart
                labels={_.keys(r.activities_by_dessa)}
                datasets={[
                  {
                    label: "activities",
                    data: _.values(
                      filterSelectedValue
                        ? filterByGradeLevelInCategories(
                          r.activities_by_dessa,
                          filterSelectedValue,
                        )
                        : r.activities_by_dessa,
                    ).map((a) => a.length),
                    backgroundColor: `rgba(${uaTeal}, 0.75)`,
                  },
                ]}
                stepSize={1}
                onClick={handleActivitiesByDESSAClick}
                type={type}
              />
              <div className="count_Dessa">
                <span>{commonStrings.countByDessa}</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="group px-4 py-4 SEL_quality_background">
          <h4>{commonStrings.schoolYearProgress}</h4>
          <BarChart
            labels={[
              "Activities
              "SEL Indicators",
              "Universal",
              "Targeted",
              "Tertiary",
            ]}
            datasets={growthScoresDataSets}
            mb={3}
            legend
          />
          <h5>{commonStrings.noSpringScore}</h5>
        </div> */}
      </div>

      {/* {window.gon.CURRENT_SCHOOL_PROGRAM_MATRIX.student_population_count && (
        <SELSchoolCapacity
          type={typeForStudentCapacity}
          setType={setTypeForStudentCapacity}
        />
      )} */}
      <div id="pdfContent4">
        {/* {matrixData.student_population && ( */}
        {type === "student" && (
          <SELSchoolCapacity
            type={typeForStudentCapacity}
            setType={setTypeForStudentCapacity}
            id="pdfContent5"
            id1="pdfContent6"
            id2="pdfContent7"
            id3="pdfContent8"
            setFilterSelectedValue={setFilterSelectedValue}
            filterSelectedValue={filterSelectedValue}
            matrixData={matrixData}
          />
        )}
        {/* )} */}
      </div>

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
        style={{
          top: 250,
          width: 670,
          display: "flex",
          justifyContent: "center",
        }}
        className="custom-modal"
      >
        <div dangerouslySetInnerHTML={{ __html: modalContent }} />
      </Modal>
    </div>
  )
}

export default ProgramMatrixSELQuality
