import React, { useEffect, useState } from "react"
import { Row, Col, Divider } from "antd"
// import VideoModal from "../VideoModal";
// import ReleaseVidoeCard from "../ReleaseVidoeCard"
import ResourcesPage from "../Resources/ResourcePage"
import NewResource from "./NewResource"

const OverallResources = ({ latestReleaseNotes }) => {
  const [activeButton, setActiveButton] = useState(0)
  // const [openModal, setOpenModal] = useState(false)
  // const [clickedVideoObject, setClickedVideoObject] = useState({})
  const [loading, setLoading] = useState(true)
  const [groupedResources, setGroupedResources] = useState([])
  // const [releaseLoading, setReleaseLoading] = useState(false)
  const [headerInfo, setHeaderInfo] = useState([])

  const activeHeader = headerInfo.length > 0 ? headerInfo[activeButton] : null

  const fetchDataForActiveTab = () => {
    if (!activeHeader) return

    const resourceTypeName = activeHeader.resource_type_name
    console.log("Fetching data for:", resourceTypeName)

    const url = resourceTypeName === "All"
      ? "/admin_resources/filter_by_category"
      : `/admin_resources/filter_by_category?resource_type=${resourceTypeName}`

    APIClient.get(url, (response) => {
      console.log("API Response:", response)
      setGroupedResources(response || [])
      setLoading(false)
    })
  }

  const fetchReleaseData = () => {
    if (
      latestReleaseNotes
      && latestReleaseNotes.length > 0
      && activeHeader
      && activeHeader.resource_type_name === "Release"
    ) {
      // setReleaseLoading(true)
      setTimeout(() => {
        // setReleaseLoading(false)
      }, 2000)
    }
  }

  useEffect(() => {
    if (headerInfo.length > 0) {
      fetchDataForActiveTab()
      fetchReleaseData()
    }
  }, [activeButton, headerInfo])

  const handleTabClick = (tabIndex) => {
    if (activeButton === tabIndex) {
      setLoading(true)
      fetchDataForActiveTab()
    } else {
      setActiveButton(tabIndex)
    }
  }

  return (
    <Row justify="center" className="deployedFontFamily">
      {/* <VideoModal
        openStatus={openModal}
        setOpenStatus={setOpenModal}
        clickedVideoObject={clickedVideoObject}
      /> */}

      <Col xs={24} sm={24} md={24} lg={24} className="d-flex">
        <NewResource
          activeButton={activeButton}
          setActiveButton={handleTabClick}
          headerInfo={headerInfo}
          setLoading={setLoading}
        />
      </Col>

      <Col xs={24} sm={24} md={24} lg={20}>
        <Divider />
      </Col>

      <Col xs={24} sm={24} md={24} lg={20}>
        {/* {activeHeader && activeHeader.resource_type_name === "Release" ? (
          releaseLoading ? (
            <div className="loading-container">
              <Spin size="large" />
            </div>
          ) : (
            <ReleaseVidoeCard
              latestReleaseNotes={latestReleaseNotes}
              currentUser={currentUser}
              setOpenModal={setOpenModal}
              setClickedVideoObject={setClickedVideoObject}
            />
          )
        ) : ( */}
        <ResourcesPage
          tab={activeButton}
          groupedResources={groupedResources}
          resourceType={activeHeader && activeHeader.resource_type_name}
          loading={loading}
          setHeaderInfo={setHeaderInfo}
        />
      </Col>
    </Row>
  )
}

export default OverallResources
