const CommonColors = {
  UA_TEAL: "#3CDCDC",
  UA_RED: "#A6192E",
  UA_BOLD_BLACK: "#565656",
  UA_GREY: "#e5e5e5",
  UA_WHITE: "#d9d9d9",
  UA_BLUE_DARK: "#3e4d60",
  UA_GRAPH_BACKGROUND: "#0ca4a5",
  UA_MTSS_PDF_HEADER: "#575555",
  UA_MTSS_PDF_ACTIVITY: "#333333",
  UA_MTSS_TIER_ACTIVITY: "#f9f9f9",
  UA_NO_ACTIVITY: "#888888",
  UA_MTSS_HEADER_WHITE: "#FFFFFF",
  UA_MTSS_INDIACTOR: "#b9b9b9",
  UA_MTSS_NESTED_RED: "#073763",
  UA_MTSS_INDIACTOR_BLUE: "#E7A922",
  UA_MTSS_TIER: "#33485D",
}

export default CommonColors
