const CommonConstants = {
  Grades: [
    "PreK",
    "K",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ],
  tierPriority: ["Tertiary", "Targeted", "Universal"],

  descriptionsResources: {
    Data: "Valid data is collected periodically and used to review and improve program implementation.",
    "Program Review":
      "The school has taken steps to incorporate program delivery into relevant ongoing and future activities.",
    Coordination:
      "SEL Leadership Team and implementing staff can communicate how the program fits with past, ongoing and/or future activities in the school.",
    Communication:
      "There is ongoing communication about the program within the school.",
    "Physical Visibility":
      "There is evidence that program use is visible within the school and to the general public.",
    Leadership:
      "SEL Leadership Team have a clear vision for the program and are active in program implementation.",
    "Program Fit/Reinvention":
      "There is evidence that the program has been adapted to meet specific needs of the school.",
    Range: "Evidence of program concepts embedded in a variety of contexts.",
    "School Norms":
      "Program concepts have been routinized with fidelity in the school.",
  },

  fileExtensions: [".pdf", ".png", ".jpeg", ".gif", "jpg"],

  descriptions: [
    {
      title: "Why should I use the RSP Matrix?",
      content: `<div>
         <h3 class="title-tour">Why should I use the RSP Matrix?</h3>
         <span>
           <strong>Purpose:</strong>The first step in your SEL journey is to understand what SEL work 
           is already happening at your school. The Matrix allows you to organize activities 
           that are happening at your school that contribute to SEL. It ensures that all 
           activities are well-documented, making it easier to track progress, identify gaps, 
           and allocate resources efficiently.
         </span>
       </div>`,
    },
    {
      title: "Accessing the Matrix",
      content: `<div>
         <h3 class="title-tour">Accessing the Matrix</h3>
         <ol>
           <li class="tour-sub-description">
             From your Resilient Scholars Portal main dashboard navigate to the 
             <strong>“Program Matrix”</strong> section from the dashboard or the menu at the top.
           </li>
           <li class="tour-sub-description">Click to <strong>“View/Add to Matrix”</strong></li>
         </ol>
       </div>`,
    },
    {
      title: "Matrix Dashboard and Domains",
      content: `<div>
         <h3 class="title-tour">Matrix Dashboard and Domains</h3>
         <ol>
           <li class="tour-sub-description">This will take you to the Matrix dashboard.</li>
           <li class="tour-sub-description">
             Before adding activities, you must add the grades at your school and the number of students in each grade. Select the grade levels here. Enter the student population for each grade to the right.
           </li>
           <li class="tour-sub-description">Choose one of the five domains to add an activity to:
             <ol type="a" class="bold-list">
              <li>Instructional Practices</li>
              <li>Social Emotional Supports</li>
              <li>Behavior Supports</li>
              <li>Extracurricular Activities</li>
              <li>Adult SEL</li>
            </ol>
           </li>
           <li class="tour-sub-description">There are two ways you can add activities:
             <ol type="a" class="bold-list">
              <li>Create your own</li>
              <li>Choose from selection of activities in the system</li>
            </ol>
           </li>
         </ol>
       </div>`,
    },
    {
      title: "Adding Activities - Step 1",
      content: `<div>
       <h3 class="title-tour">Adding Activities</h3>
      <span><strong>Creating your own activity:</strong></span>
       <ol type="a">
         <li class="tour-sub-description">Select one of the five domains listed</li>
         <li class="tour-sub-description">Click the red <strong>‘Add Activity’</strong> button next to the domain.</li>
         <li class="tour-sub-description">Manually enter the following details (<strong>*</strong> are required fields):</li>
         <ul>
           <li type="i"><strong>*Activity Name:</strong> Be clear and concise</li>
        <li type="i">
           <strong>*Tier of Support: Select one of the following- Universal, Targeted and Tertiary</strong> 
         </li>
          <ol>
           <li class="tour-sub-description"><strong>If you select Universal-</strong> Students supported will automatically populate with the total number of students across grades.</li>
           <li class="tour-sub-description">If you select Targeted or Tertiary- please add the grade levels that experience the activity,  the number of students per grade level that this activity can support, and how many students per grade level it does currently support.</li>
         </ol>
         </ul>
       </ol>
       <div>
        <strong>Adding Activities (Continued)</strong>
       </div>
     </div>`,
    },
    {
      title: "Adding Activities - Step 2",
      content: `<div>
       <h3 class="title-tour">Adding Activities</h3>
       <span><strong>Creating your own activity:</strong></span>
       <ol type="i" start="3">
         <li>
           <strong>*Activity Length:</strong> Select from the dropdown how long the school has engaged in this activity.
         </li>
         <li>
           <strong>*Description:</strong> Enter a detailed description of the activity, including its objectives and expected outcomes.
         </li>
         <li>
           <strong>Data Collected:</strong> Indicate, if any, what data is collected to demonstrate the effectiveness of the activity. 
           If none, leave this blank.
         </li>
       </ol>
        <div>
        <strong>Adding Activities (Continued)</strong>
       </div>
     </div>`,
    },
    {
      title: "Adding Activities - Step 3",
      content: `<div>
       <h3 class="title-tour">Adding Activities</h3>
       <span><strong>Creating your own activity:</strong></span>
     <ol type="i" start="6">
    <li>
      <strong>*SEL Indicators:</strong> For each activity, please select up to 3 SEL indicators that this SEL activity promotes or reinforces:
      <ol type="1">
        <li class="tour-sub-description">
          From the dropdown, select the SEL indicator that is applicable and click ‘Add Indicator.’
        </li>
        <li class="tour-sub-description">
          Then select whether this activity promotes or reinforces this indicator.
        </li>
        <li class="tour-sub-description">
          Complete the sentence stem provided to indicate how this activity promotes or reinforces this indicator.
        </li>
      </ol>
    </li>
  </ol>
     </div>`,
    },
    {
      title:
        "Instead of creating your own activities you can also select from the list of activities in the system:",
      content: `<div>
         <h3 class="title-tour">Instead of creating your own activities you can also select from the list of activities in the system:</h3>
         <ol type ="a">
           <li class="tour-sub-description">At the bottom of each domain is a list of possible activities.Select See more to see the full list</li>
           <li class="tour-sub-description">Click the activity to see  a description and then select Create this Activity</li>
           <li class="tour-sub-description">The activity <strong>name</strong> and <strong>description</strong> fields will automatically populate but you can edit</li>
         </ol>
        <span>Then complete the rest of the fields on the page</span>
       </div>`,
    },
    {
      title: "Saving and Editing Activities",
      content: `<div>
         <h3 class="title-tour">Saving and Editing Activities</h3>
         <ol>
           <li class="tour-sub-description">Once all the fields are filled in, click the “Finish” button.</li>
           <li class="tour-sub-description">Your activity will now be listed in the Matrix under the selected domain.</li>
           <li class="tour-sub-description">Click edit to edit an existing activity</li>
           <li class="tour-sub-description">Click delete to delete an existing activity</li>
           <li class="tour-sub-description">Activities will show as ‘Incomplete’ if required fields are not filled in and/or the data field is empty</li>
         </ol>
        <span>Once you have added all your activities, you are ready to view your reports!</span>
       </div>`,
    },
  ],
  fullIndicatorList: [
    "All",
    "Communication",
    "Coordination",
    "Data",
    "Leadership",
    "Physical Visibility",
    "Program Fit/Reinvention",
    "Program Review",
    "Range",
    "School Norms",
  ],
  panelColors: ["#E7A922", "#0CA4A5", "#33485D"],
}

export default CommonConstants
