import React from "react"
import { Tooltip } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"
import commonStrings from "../../commonStrings"
import TypeToggle from "./TypeToggle"
import { processTierData } from "../../utilities"

const IndicatorList = ({ data, type }) => (data.length > 0 ? (
  data.map((section, idx) => (
    <div key={idx} className="section">
      <span className="section-title">{section.title}</span>
      <ul className="section-list">
        {section.keys.map((keyData, i) => (
          <li style={{ cursor: "pointer", display: "flex" }} key={i}>
            {keyData.items}
            <Tooltip
              overlayInnerStyle={{
                backgroundColor: "#fafafa",
                color: "black",
                border: "1px solid #c0c0c0",
                borderRadius: "5px",
                width: "350px",
              }}
              overlayStyle={{ width: "100%" }}
              title={(
                <div style={{ fontFamily: "Archivo Narrow" }}>
                  <div className="d-flex align-items-center">
                    <span>Activities with similar Indicators:</span>
                  </div>
                  <div className="scroll-tooltip-mtss">
                    <ul>
                      {keyData.activities.map((activity, activityIndex) => (
                        <React.Fragment key={activityIndex}>
                          <li
                            style={{
                              textAlign: "justify",
                              paddingRight: "17px",
                              color: "#515151",
                            }}
                          >
                            {activity}
                          </li>
                          <div className="liner-div" />
                        </React.Fragment>
                      ))}
                    </ul>
                  </div>
                </div>
                )}
            >
              <span
                className="indicatorToolTip mx-1"
                style={{
                  color: type === "student" ? "#0CA4A5" : "#33485D",
                }}
              >
                <InfoCircleOutlined />
              </span>
            </Tooltip>
          </li>
        ))}
      </ul>
    </div>
  ))
) : (
  <div className="no-data-message">{commonStrings.nodataAvailable}</div>
))

const MtssIndicators = ({ Mtssreport, type, setType }) => {
  const tiers = Mtssreport && Mtssreport.activity_breakdown_by_tier
    ? Mtssreport.activity_breakdown_by_tier
    : []

  return (
    <div key={(tiers, type)} className="mtss-indicators-container">
      <div className="group py-4 Student-adult-toggle-buttons">
        <TypeToggle type={type} setType={setType} />
      </div>

      {tiers.map((tier, index) => {
        const { promotionData, reinforcementData } = processTierData(
          tier,
          type,
        )

        return (
          <div key={index} className="tier-section">
            <div className="indicators-columns">
              <div className="indicator-column">
                <div className="header promotion-header">
                  {commonStrings.promotionText}
                </div>
                <IndicatorList data={promotionData} type={type} />
              </div>

              <div className="indicator-column">
                <div className="header reinforcement-header">
                  {commonStrings.reinforcementText}
                </div>
                <IndicatorList data={reinforcementData} type={type} />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MtssIndicators
