import React from "react"
import { Col } from "antd"
import IconConstants from "../../IconConstants"

const NewResource = ({
  activeButton,
  setActiveButton,
  headerInfo,
  setLoading,
}) => {
  console.log("headerInfo", headerInfo)

  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      className="tabs-container"
      style={{ display: "flex" }}
    >
      <div className="main-tabs">
        {headerInfo.map((tab, index) => {
          const iconData = IconConstants[tab.resource_type_name] || {}
          return (
            <div key={index}>
              <button
                className={`tab-button ${
                  activeButton === index ? "active" : ""
                }`}
                onClick={() => {
                  setActiveButton(index)
                  setLoading(true)
                }}
              >
                {iconData.active && iconData.default ? (
                  <img
                    className="icons-resources"
                    src={
                      activeButton === index
                        ? iconData.default
                        : iconData.active
                    }
                    alt={`${tab.resource_type_name} Icon`}
                  />
                ) : null}

                <span className="tab-name">
                  {tab.resource_type_name}
                  {/* ({tab.count}) */}
                </span>
              </button>
            </div>
          )
        })}
      </div>
    </Col>
  )
}
export default NewResource
