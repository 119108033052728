import React, { useState, useEffect } from "react"
import {
  Card, Input, Modal, Row, Col, Spin, Dropdown, Button,
} from "antd"
import { CloseCircleOutlined, DownOutlined } from "@ant-design/icons"
// import Vector from "../../../assets/images/Vector.png";
// import Frame from "../../../assets/images/Frame.png";
import Playicon from "../../../assets/images/Playicon.png"
import commonStrings from "../../commonStrings"
import {
  getIconByExtension,
  transformResources,
  // getCategoryCounts,
  isExternalFile,
  getVideoModalClass,
  isVideoAttachment,

} from "../../utilities"
import CommonConstants from "../../CommonConstants"
import CategoryMenu from "./CategoryMenu"
import IframePlayer from "../Iframe"
import IndicatorList from "./Indicatorlist"
import UAPagination from "./Pagination"

const ResourcesPage = ({
  groupedResources,
  resourceType,
  loading,
  setHeaderInfo,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)
  console.log("🚀 ~ selectedResource:", selectedResource)
  const [searchTerm, setSearchTerm] = useState("")
  const [transformedResources, setTransformedResources] = useState([])
  const [selectedIndicator, setSelectedIndicator] = useState(["All"])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  useEffect(() => {
    const indicatorParam = selectedIndicator.length === 0 || selectedIndicator.includes("All")
      ? ""
      : `?indicator_type=${selectedIndicator.join(",")}`

    const url = `/admin_resources/resource_counts${indicatorParam}`

    APIClient.get(url, (response) => {
      if (response) {
        setHeaderInfo(response)
      }
    })
  }, [selectedIndicator])

  const handleCardClick = (resource) => {
    setSelectedResource(resource)
    setIsModalVisible(true)
  }
  const handleModalClose = () => {
    setIsModalVisible(false)
    setSelectedResource(null)
  }
  const handleSearch = (event) => {
    const term = event.target.value
    setSearchTerm(term)
  }

  useEffect(() => {
    const filteredResources = groupedResources.filter(
      (resource) => (selectedIndicator.length === 0
          || selectedIndicator.includes("All")
          || selectedIndicator.some((indicator) => resource.indicator_type.includes(indicator)))
        && ((resource.title || "")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
          || (resource.description || "")
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
          || resource.indicator_type.some((type) => type.toLowerCase().includes(searchTerm.toLowerCase()))),
    )

    const sortedResources = filteredResources.sort((a, b) => (a.title || "").toLowerCase().localeCompare((b.title || "").toLowerCase()))

    const updatedResources = transformResources(sortedResources)
    setCurrentPage(1)
    setTransformedResources(updatedResources)
  }, [groupedResources, resourceType, selectedIndicator, searchTerm])
  const paginatedResources = transformedResources.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  )

  const renderCover = (resource) => {
    if (!resource) {
      return null
    }
    if (resource.type === "youtube") {
      return (
        <div className="default-thumbnail">
          <IframePlayer
            src={resource.youtube_link.replace("watch?v=", "embed/")}
            width="100%"
            height={200}
            title="YouTube video player"
            className="default-thumbnail"
          />
        </div>
      )
    }
    if (
      resource.type === "attachment"
      && resource.attachment
      && resource.attachment.url
    ) {
      const extension = resource.attachment.url.split(".").pop().toLowerCase()
      if (["jpg", "jpeg", "png"].includes(extension)) {
        return (
          <img
            src={resource.attachment.url}
            alt="Preview"
            className="image-preview"
          />
        )
      }
      if (["mp4", "mov", "avi"].includes(extension)) {
        return (
          <div className="video-container">
            <video preload="metadata">
              <track
                kind="captions"
                srcLang="en"
                label="English captions"
                default
              />
              <source
                src={resource.attachment.url}
                type={`video/${extension}`}
              />
              {commonStrings.browserSupport}
            </video>
            <img src={Playicon} alt="play" className="play-icon" />
          </div>
        )
      }
      return (
        <div className="default-thumbnail">
          <div className="icon-main">
            <img
              className="pdf-icon-resources"
              src={getIconByExtension(extension)}
              alt={`${extension.toUpperCase()} Icon`}
            />
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <Col xs={24} sm={24} md={24} lg={24} className="tabs-container d-flex">
      <div className="rubric-resource-container">
        <Col xs={24} sm={24} md={24} lg={24} className="tabs-container d-flex">
          <div className="filter-main">
            <div className="inner-filters-all">
              <div className="all-filter-button-main">
                <Dropdown
                  overlay={(
                    <CategoryMenu
                      // categoryCounts={categoryCounts}
                      selectedIndicator={selectedIndicator}
                      setSelectedIndicator={setSelectedIndicator}
                      fullIndicatorList={CommonConstants.fullIndicatorList}
                    />
                  )}
                  trigger={["click"]}
                >
                  <Button className="filter-button">
                    <span>
                      {selectedIndicator.length === 1
                      && selectedIndicator.includes("All")
                        ? "All"
                        : `${selectedIndicator.length} selected`}
                    </span>{" "}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>

              <div className="inner-filters">
                <Input
                  allowClear
                  placeholder="Search"
                  className="search-input"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </Col>
        {loading ? (
          <div className="loading-container">
            <Spin size="large" />
          </div>
        ) : paginatedResources.length > 0 ? (
          <>
            <Row className="resource-row">
              {paginatedResources.map((resource, index) => (
                <div key={index} className="resource-col">
                  <Card
                    hoverable
                    className="resource-card"
                    onClick={() => handleCardClick(resource)}
                    cover={(
                      <div className="ant-card-cover">
                        {renderCover(resource)}
                      </div>
                    )}
                  >
                    <div className="main-feature-description">
                      <div className="name-modals-resources">
                        <span>{resource.title ? resource.title : "N/A"}</span>
                      </div>
                      <div className="description-modals-resources">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: resource.description,
                          }}
                        />
                      </div>
                    </div>

                    <IndicatorList
                      indicators={
                        Array.isArray(resource.indicator_type)
                          ? resource.indicator_type
                          : []
                      }
                    />
                  </Card>
                </div>
              ))}
            </Row>
            {transformedResources.length > pageSize && (
              <div className="pagination-container">
                <UAPagination
                  currentPage={currentPage}
                  pageSize={pageSize}
                  totalItems={transformedResources.length}
                  onPageChange={setCurrentPage}
                  onPageSizeChange={setPageSize}
                />
              </div>
            )}
          </>
        ) : (
          <Col span={24} className="no-data">
            <p>{commonStrings.noData}</p>
          </Col>
        )}
        <Modal
          centered
          width={630}
          key={selectedResource ? selectedResource.id : "default"}
          keyboard
          visible={isModalVisible}
          closable={false}
          footer={null}
          onCancel={handleModalClose}
          title={
            selectedResource ? (
              <div className="custom-title-resources">
                <span
                  className="close-button-resources"
                  onClick={handleModalClose}
                >
                  <CloseCircleOutlined />
                </span>
              </div>
            ) : null
          }
          className={getVideoModalClass(selectedResource)}
        >
          {selectedResource
          && selectedResource.type === "youtube"
          && selectedResource.youtube_link ? (
            <div>
              <div className="modal-content-video">
                <IframePlayer
                  src={selectedResource.youtube_link.replace(
                    "watch?v=",
                    "embed/",
                  )}
                  width="100%"
                  height={500}
                  title="YouTube video player"
                  className="custom-player-resources"
                />
              </div>
              <div className="modal-description-container-resources ">
                <div className="modal-description custom-scrollbar">
                  <div className="release-message">
                    <div className="title-resources">
                      <span>Title</span>
                    </div>
                    <div
                      className="new-features-description-name"
                      dangerouslySetInnerHTML={{
                        __html: selectedResource.name,
                      }}
                    />
                  </div>
                  <div>
                    <IndicatorList
                      indicators={selectedResource.indicator_type}
                      showAll
                      videoList
                    />
                  </div>
                  <div className="new-feature">
                    <span className="new-feature-text">
                      {commonStrings.description}
                    </span>
                  </div>
                  <div
                    className="new-features-description"
                    dangerouslySetInnerHTML={{
                      __html: selectedResource.description,
                    }}
                  />
                </div>
              </div>
            </div>
            ) : selectedResource
            && selectedResource.attachment
            && isVideoAttachment(selectedResource.attachment) ? (
              <div>
                <div className="modal-content-video">
                  <video
                    className="custom-player-resources"
                    controls
                    width="100%"
                    height="100%"
                    style={{ maxHeight: "500px" }}
                  >
                    <track
                      kind="captions"
                      srcLang="en"
                      label="English captions"
                    />
                    <source
                      src={selectedResource.attachment.url}
                      type="video/mp4"
                    />
                    {commonStrings.browserSupport}
                  </video>
                </div>
                <div className="modal-description-container-resources ">
                  <div className="modal-description custom-scrollbar">
                    <div className="release-message">
                      <div className="title-resources">
                        <span>Title</span>
                      </div>
                      <div
                        className="new-features-description-name"
                        dangerouslySetInnerHTML={{
                          __html: selectedResource.name,
                        }}
                      />
                    </div>
                    <div className="new-feature">
                      <span className="new-feature-text">
                        {commonStrings.description}
                      </span>
                    </div>
                    <div
                      className="new-features-description"
                      dangerouslySetInnerHTML={{
                        __html: selectedResource.description,
                      }}
                    />
                  </div>
                </div>
              </div>
              ) : selectedResource
            && selectedResource.type === "attachment"
            && selectedResource.attachment
            && selectedResource.attachment.url ? (
              <div className="modal-content-resource">
                <div className="file-icon">
                  <img
                    src={getIconByExtension(
                      selectedResource.attachment.url
                        .split(".")
                        .pop()
                        .toLowerCase(),
                    )}
                    alt="File Icon"
                    className="file-preview-icon"
                  />
                </div>
                <div className="file-details custom-scrollbar">
                  <span className="file-details-name">
                    {selectedResource.title}
                  </span>
                  <span className="file-details-description">
                    <div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: selectedResource.description,
                        }}
                      />
                    </div>
                    <div>
                      <IndicatorList
                        indicators={
                        Array.isArray(selectedResource.indicator_type)
                          ? selectedResource.indicator_type
                          : []
                      }
                        showAll
                      />
                    </div>
                  </span>
                </div>
                <div className="main-download-button-resources">
                  <a
                    href={selectedResource.attachment.url}
                    target={
                    isExternalFile(selectedResource.attachment.url)
                      ? "_blank"
                      : "_self"
                  }
                    rel="noopener noreferrer"
                    download
                    className="download-button-resources"
                  >
                    {commonStrings.download}
                  </a>
                </div>
              </div>
                ) : null}
        </Modal>
      </div>
    </Col>
  )
}
export default ResourcesPage
