import React from "react"
import { Menu, Checkbox, Button } from "antd"
import commonStrings from "../../commonStrings"

const CategoryMenu = ({
  // categoryCounts,
  selectedIndicator,
  setSelectedIndicator,
  fullIndicatorList,
}) => {
  const handleCheckboxChange = (indicator) => {
    if (indicator === "All") {
      setSelectedIndicator(["All"])
    } else {
      const newSelectedIndicator = selectedIndicator.includes(indicator)
        ? selectedIndicator.filter((item) => item !== indicator)
        : [...selectedIndicator.filter((item) => item !== "All"), indicator]

      setSelectedIndicator(
        newSelectedIndicator.length === 0 ? ["All"] : newSelectedIndicator,
      )
    }
  }

  const handleClearAll = () => {
    setSelectedIndicator(["All"])
  }

  return (
    <Menu>
      <Menu.Item key="clear-all">
        <Button
          className="reset-filter-resources"
          type="link"
          onClick={handleClearAll}
          disabled={
            selectedIndicator.length === 1 && selectedIndicator.includes("All")
          }
        >
          {commonStrings.clearAll}
        </Button>
      </Menu.Item>

      {fullIndicatorList.map((indicator) => (
        <Menu.Item key={indicator}>
          <Checkbox
            className="overall-resources-checkbox"
            checked={selectedIndicator.includes(indicator)}
            onChange={() => handleCheckboxChange(indicator)}
          >
            {indicator}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default CategoryMenu
