import React from "react"
import PropTypes from "prop-types"
import commonStrings from "../../commonStrings"

const IndicatorList = ({ indicators = [], showAll = false, videoList }) => {
  const filteredIndicators = indicators.filter(
    (indicator) => typeof indicator === "string" && indicator.trim().length > 0,
  )

  const justifyContent = showAll
    ? videoList
      ? "flex-start"
      : "center"
    : "flex-start"

  return (
    <div className="indicator-type-container" style={{ justifyContent }}>
      {filteredIndicators.length > 0 ? (
        filteredIndicators
          .slice(0, showAll ? filteredIndicators.length : 2)
          .map((indicator, index) => (
            <span key={index} className="indicator-type-tag">
              {indicator}
            </span>
          ))
      ) : (
        <span className="indicator-type-tag">{commonStrings.noIndicator}</span>
      )}
      {!showAll && filteredIndicators.length > 2 && (
        <span className="indicator-type-more">
          +{filteredIndicators.length - 2}
        </span>
      )}
    </div>
  )
}

IndicatorList.propTypes = {
  indicators: PropTypes.arrayOf(PropTypes.string),
  showAll: PropTypes.bool,
  videoList: PropTypes.bool,
}

IndicatorList.defaultProps = {
  showAll: false,
  indicators: [],
  videoList: false,
}

export default IndicatorList
