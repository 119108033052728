import React, { useState, useEffect } from "react"
import { Modal, Tooltip } from "antd"
import { CloseCircleOutlined } from "@ant-design/icons"
import commonStrings from "../../commonStrings"
import CommonConstants from "../../CommonConstants"

const Pyramid = ({ Mtssreport, setSortedPyramidData, sortedPyramidData }) => {
  const pyramidHash = Mtssreport.pyramid_Hash || []
  const [selectedTier, setSelectedTier] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedTierData, setSelectedTierData] = useState(null)
  const [activities, setActivities] = useState([])

  const handleTierClick = (tier) => {
    setSelectedTier(tier)
    setIsModalVisible(true)
  }

  const handleCloseModal = () => {
    setSelectedTier(null)
    setIsModalVisible(false)
  }

  useEffect(() => {
    setSortedPyramidData(
      [...pyramidHash].sort(
        (a, b) => CommonConstants.tierPriority.indexOf(a.tier)
          - CommonConstants.tierPriority.indexOf(b.tier),
      ),
    )

    const tierData = pyramidHash.find((tier) => tier.tier === selectedTier)
    setSelectedTierData(tierData)
    setActivities((tierData && tierData.data) || [])
  }, [pyramidHash, selectedTier])

  return (
    <div className="main-pyramid">
      <span className="main-pyramid-text">{commonStrings.dataOverview} </span>
      <div className="inner-main-pyramid">
        <div className="pyramid">
          {sortedPyramidData.map((tier, index) => (
            <Tooltip
              key={index}
              title={`Click to view Tier ${index + 1} (${
                tier.tier
              }) activities`}
              placement="bottom"
              className="pyramid-tooltip"
            >
              <div
                className={`pyramid__section pyramid__section--tier${
                  index + 1
                }`}
                style={{
                  backgroundColor:
                    index === 0
                      ? "#33485D"
                      : index === 1
                        ? "#0CA4A5"
                        : "#E7A922",
                  cursor: "pointer",
                }}
                onClick={() => handleTierClick(tier.tier)}
              >
                <div className="pyramid__text">
                  <div className="pyramid-count">{tier.count}</div>
                  <div className="pyramid-activity-title-text">
                    {commonStrings.activitiesTitle}
                  </div>
                  <div className="pyramid-tier-text">
                    {commonStrings.tierTitle}{" "}
                    {CommonConstants.tierPriority.length - index} ({tier.tier})
                  </div>
                </div>
              </div>
            </Tooltip>
          ))}

          <Modal
            title={null}
            visible={isModalVisible}
            onCancel={handleCloseModal}
            width={600}
            className="pyramid-modal"
            closeIcon={<CloseCircleOutlined />}
            footer={null}
          >
            {selectedTierData && (
              <div className="pyramid-modal-content">
                <div className="pyramid-modal-header">
                  <div className="pyramid-activities">
                    {selectedTierData.count} {commonStrings.activitiesTitle}
                  </div>
                  <div className="pyramid-modal-tier">
                    {commonStrings.tierTitle}{" "}
                    {pyramidHash.indexOf(selectedTierData) + 1} (
                    {selectedTierData.tier})
                  </div>
                </div>
                {activities.length > 0 ? (
                  <ul className="pyramid-modal-activity-list custom-scrollbar">
                    {activities.map((activity) => (
                      <li
                        key={activity.id}
                        className="pyramid-modal-activity-item"
                      >
                        <div className="acitiviyt-time-pyramid">
                          <span className="acitiviy-pyramid">
                            {activity.activity}
                          </span>{" "}
                          <span>({activity.length_of_time})</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="no-data-message-pyramid">
                    {commonStrings.nodataAvailable}
                  </div>
                )}
              </div>
            )}
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default Pyramid
