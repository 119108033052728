import React from "react"
import { Button } from "antd"

const GenericButton = ({
  className,
  onClick,
  children,
  disabled,
  type,
  style,
}) => (
  <Button
    className={className}
    onClick={onClick}
    disabled={disabled}
    type={type || "default"}
    style={style}
  >
    {children}
  </Button>
)

export default GenericButton
