import React, { useState, useEffect, useLayoutEffect } from "react"
import { DatabaseFilled } from "@ant-design/icons"

import { PDFDownloadLink } from "@react-pdf/renderer"

import { Modal } from "antd"
import ProgramMatrixSELQuality from "./SELQuality"
import commonStrings from "../../commonStrings"
import ProgramMatrixSELScan from "./SELScan"
import DessaReport from "./DessaReport"
import ActivityDetails from "./ActivityDetails"
import Loader from "../CommonComponents/Loader"
import backIcon from "../../../assets/images/back-icon.png"
import ToolTip from "../CommonComponents/ToolTip"
import Pyramid from "./Pyramid"
import Mtss from "./Mtss"
import pdfSvg from "../../../assets/images/pdf-file.svg"
import MTSSPdf from "./MTSSPdf"
import { getDateForReport, getSchoolLogo } from "../../utilities"
import dottedLoader from "../../../assets/images/dottedloader.gif"
import GenericButton from "./GenericButton"

function Reports({ matrix1, currentSchool }) {
  const [Mtssreport, setMtssreport] = useState([])
  const [conditionalMessage, setConditionalMessage] = useState([])
  const [tierPercentages, setTierPercentages] = useState([])
  const [tierActivities, setTierActivities] = useState([])
  const [totalActivities, setTotalActivities] = useState(0)
  const [type, setType] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false)
  useEffect(() => {
    APIClient.get(
      "/program_matrix/program_matrix_items/mtss_report",
      (response) => {
        setMtssreport(response)
      },
    )
  }, [])

  const [workflowState] = useState(matrix1.workflow_state)
  const [category, setCategory] = useState()
  const urlParams = new URLSearchParams(window.location.search)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (urlParams.get("name")) {
      setCategory(urlParams.get("name").replace("_", " "))
    }
  }, [window.location.search])

  useLayoutEffect(() => {
    if (category) {
      setTimeout(() => {
        setIsLoading(false)
      }, 300)
    }
  }, [category])

  const popovers = [
    "This report organizes the school or district activities by SEL indicator. Use this report to understand how existing activities support social-emotional development. Add or modify activities in order to increase opportunity to develop that indicator.",
    "Use this report to articulate the relationship between the school or district activities and the social and emotional indicators the activities support. Share this report with other stakeholders to build staff and community alignment around how an activity supports SEL.",
    "Use this view to assess the quality of your SEL programming against the definition of high-quality SEL.",
    "This report organizes the school or district by DESSA Competency. Use this report to understand how existing activities support social-emotional development. Add or modify activities in order to increase opportunity to develop that DESSA competency",
  ]

  const [array] = useState([
    "SEL Scan",
    "SEL Quality",
    "Activity Details",
    "DESSA Report",
    "MTSS Report",
  ])

  const checkActivities = (array) => {
    const activities = array.map((obj) => obj.activity)
    return activities.some((activity) => activity !== null)
  }

  function getButtonBackgroundColor(text) {
    switch (text) {
      case "SEL Scan":
        return "#A6192E"
      case "SEL Quality":
        return "#33485D"
      case "Activity Details":
        return "#E7A922"
      case "DESSA Report":
        return "#0CA4A5"
      case "MTSS Report":
        return "#333333"
      default:
        return ""
    }
  }

  const setCurrentURL = (value) => {
    const urlMapping = {
      "SEL Scan": "SEL_Scan",
      "SEL Quality": "SEL_Quality",
      "Activity Details": "Activity_Details",
      "DESSA Report": "DESSA_Report",
      "MTSS Report": "MTSS_Report",
    }

    if (Object.prototype.hasOwnProperty.call(urlMapping, value)) {
      const urlName = urlMapping[value]
      const currentURL = window.location.href
      const updatedURL = new URL(currentURL)
      updatedURL.searchParams.set("name", urlName)
      window.history.replaceState({}, "", updatedURL.toString())
    } else {
      window.location.href = "/"
    }
    return null
  }

  const backToActivityLogs = () => {
    window.location.href = "/program_matrices"
  }

  const [sortedPyramidData, setSortedPyramidData] = useState([])
  const handleSortedPyramidData = (data) => {
    setSortedPyramidData(data)
  }

  const updateTierData = (activities, total) => {
    setTierActivities(activities)
    setTotalActivities(total)
  }

  const showModal = () => {
    setIsModalVisible(true)
    setIsGeneratingPDF(true)

    setTimeout(() => {
      setIsGeneratingPDF(false)
    }, 5000)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setIsGeneratingPDF(false)
  }
  const handleToggleChange = (tierIndex, newType) => {
    setType((prevTypes) => {
      const updatedTypes = [...prevTypes]
      updatedTypes[tierIndex] = newType || "student"

      return updatedTypes
    })
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div className="rubric program-matrix fontDefault">
      {checkActivities(matrix1.items) ? (
        <div className="tab-content mb-4">
          <div className="mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div onClick={backToActivityLogs}>
                  <ToolTip
                    title={commonStrings.backToActivityDashboard}
                    Body={<img className="backIconSize" src={backIcon} />}
                  />
                </div>
                <div className="categories-heading">
                  <span>{category}</span>
                </div>
              </div>

              <div className="button-group">
                {array.map(
                  (c, index) => category !== c && (
                  <button
                    key={index}
                    type="button"
                    className="btn mx-2"
                    style={{
                      backgroundColor: getButtonBackgroundColor(c),
                      color: "#FFFFFF",
                      fontSize: "17px",
                      fontWeight: "400",
                      padding: "5px",
                    }}
                    onClick={() => {
                      setCategory(c)
                      setCurrentURL(c)
                    }}
                  >
                    {c}
                  </button>
                  ),
                )}
              </div>
            </div>
            {category && (
              <div className="popover-content mt-3">
                <span>{popovers[array.indexOf(category)]}</span>
              </div>
            )}
          </div>
          {workflowState === "submitted" && (
            <div
              className={`tab-pane ${
                category === "SEL Scan" ? "active" : null
              }`}
            >
              <ProgramMatrixSELScan
                matrix={matrix1}
                currentSchool={currentSchool}
              />
            </div>
          )}
          {workflowState === "submitted" && (
            <div
              className={`tab-pane ${
                category === "SEL Quality" ? "active" : null
              }`}
            >
              <ProgramMatrixSELQuality
                matrix={matrix1}
                currentSchool={currentSchool}
              />
            </div>
          )}
          {workflowState === "submitted" && (
            <div
              className={`tab-pane ${
                category === "Activity Details" ? "active" : null
              }`}
            >
              <ActivityDetails matrix={matrix1} currentSchool={currentSchool} />
            </div>
          )}
          {workflowState === "submitted" && (
            <div
              className={`tab-pane ${
                category === "DESSA Report" ? "active" : null
              }`}
            >
              <DessaReport matrix={matrix1} currentSchool={currentSchool} />
            </div>
          )}
          {workflowState === "submitted" && (
            <div
              className={`tab-pane ${
                category === "MTSS Report" ? "active" : null
              }`}
            >
              <div>
                <div className="pdf-filters-container">
                  <div className="pdf-activity-details">
                    <button onClick={showModal} className="open-modal-button">
                      <img
                        src={pdfSvg}
                        alt="Open modal"
                        className="pdf-icon-button"
                      />
                    </button>

                    <Modal
                      title={null}
                      visible={isModalVisible}
                      onCancel={handleCancel}
                      footer={null}
                      centered
                      bodyStyle={{ textAlign: "center", padding: "30px" }}
                      className="activity-details-modal"
                    >
                      {isGeneratingPDF ? (
                        <div className="pdf-generation-container">
                          <div className="pdf-loader-section">
                            <img
                              src={dottedLoader}
                              alt="Loading..."
                              className="pdf-loader-icon"
                            />
                            <span className="pdf-preparing-text">
                              {commonStrings.pdfPreparing}
                            </span>
                            <span className="pdf-waiting-text">
                              {commonStrings.waiting}
                            </span>
                            <div className="pdf-action-buttons">
                              <GenericButton
                                onClick={handleCancel}
                                className="cancel-button"
                              >
                                {commonStrings.cancel}
                              </GenericButton>
                              <GenericButton
                                disabled
                                className="download-button disabled"
                              >
                                {commonStrings.download}
                              </GenericButton>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="pdf-ready-container">
                          <div className="pdf-info-section">
                            <img
                              src={pdfSvg}
                              alt="PDF Icon"
                              className="pdf-icon"
                            />
                            <span className="pdf-ready-text">
                              <span>
                                {commonStrings.pdfReadywithoutFilters}
                              </span>
                            </span>
                          </div>
                          <div className="pdf-action-buttons">
                            <GenericButton
                              onClick={handleCancel}
                              className="cancel-button"
                            >
                              {commonStrings.cancel}
                            </GenericButton>
                            <PDFDownloadLink
                              document={(
                                <MTSSPdf
                                  Mtssreport={Mtssreport}
                                  conditionalMessage={conditionalMessage}
                                  sortedPyramidData={sortedPyramidData}
                                  tierPercentages={tierPercentages}
                                  tierActivities={tierActivities}
                                  totalActivities={totalActivities}
                                  currentschoolLogo={getSchoolLogo(
                                    currentSchool,
                                  )}
                                  type={type}
                                />
                              )}
                              fileName={`${
                                currentSchool.name || "school_name"
                              }_report(${getDateForReport(currentSchool)}).pdf`}
                            >
                              {({ loading }) => (
                                <GenericButton className="download-button">
                                  {loading ? "Loading..." : "Download"}
                                </GenericButton>
                              )}
                            </PDFDownloadLink>
                          </div>
                        </div>
                      )}
                    </Modal>
                  </div>
                </div>
                <Pyramid
                  Mtssreport={Mtssreport}
                  setSortedPyramidData={handleSortedPyramidData}
                  sortedPyramidData={sortedPyramidData}
                />
                <Mtss
                  Mtssreport={Mtssreport}
                  setConditionalMessage={setConditionalMessage}
                  conditionalMessage={conditionalMessage}
                  tierPercentages={tierPercentages}
                  setTierPercentages={setTierPercentages}
                  updateTierData={updateTierData}
                  type={type}
                  setType={setType}
                  handleToggleChange={handleToggleChange}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="no-data">
            <DatabaseFilled />
          </div>
          <div className="no-activity mt-2">
            <span>{commonStrings.noReportGenerated}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default Reports
