import React from "react"
import { Pagination, Button } from "antd"
import Vector from "../../../assets/images/Vector.png"
import Frame from "../../../assets/images/Frame.png"

const UAPagination = ({
  currentPage,
  pageSize,
  totalItems,
  onPageChange,
  onPageSizeChange,
}) => {
  const handlePageChange = (page, size) => {
    onPageChange(page)
    onPageSizeChange(size)
  }

  return (
    <div className="pagination-container">
      <Pagination
        className="custom-pagination"
        current={currentPage}
        pageSize={pageSize}
        total={totalItems}
        onChange={handlePageChange}
        showSizeChanger
        pageSizeOptions={["10", "20", "50"]}
        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        itemRender={(page, type, originalElement) => {
          if (type === "prev") {
            return (
              <Button className="no-hover">
                <img src={Vector} alt="Previous" />
              </Button>
            )
          }
          if (type === "next") {
            return (
              <Button className="no-hover">
                <img src={Frame} alt="Next" />
              </Button>
            )
          }
          if (type === "jump-prev" || type === "jump-next") {
            return <span>...</span>
          }
          return originalElement
        }}
      />
    </div>
  )
}

export default UAPagination
