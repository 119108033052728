import React, { useEffect, useState } from "react"
import { Collapse } from "antd"
import { UpOutlined } from "@ant-design/icons"
import commonStrings from "../../commonStrings"
import MtssIndicators from "./MtssIndicators"
import {
  updateActivityBreakdownAndCalculateTotal,
  calculatePercentage,
  generateConditionalMessages,
} from "../../utilities"

import CommonConstants from "../../CommonConstants"

const { Panel } = Collapse
const nestedPanelColors = [
  "#A6192E",
  "#073763",
  "#E7A922",
  "#0CA4A5",
  "#565656",
  "#575555",
]
const Mtss = ({
  Mtssreport,
  setConditionalMessage,
  conditionalMessage,
  type,
  setType,
  tierPercentages,
  setTierPercentages,
  updateTierData,
  handleToggleChange,
}) => {
  const [activityData, setActivityData] = useState({
    updatedActivityBreakdown: [],
    totalActivities: 0,
  })

  const [showConditionalPanel, setShowConditionalPanel] = useState(false)

  useEffect(() => {
    const { updatedActivityBreakdown, totalActivities } = updateActivityBreakdownAndCalculateTotal(
      Mtssreport.activity_breakdown_by_tier || [],
    )
    setActivityData({ updatedActivityBreakdown, totalActivities })

    const accumulatedTierActivities = []
    const thresholdsList = updatedActivityBreakdown.map((tier) => {
      const tierActivities = tier.categories.reduce(
        (count, category) => (category.category !== commonStrings.promotedReinforcedIndicators
          ? count + category.items.length
          : count),
        0,
      )
      accumulatedTierActivities.push(tierActivities)

      const percentage = calculatePercentage(tierActivities, totalActivities)
      return percentage
    })
    setTierPercentages(thresholdsList)
    updateTierData(accumulatedTierActivities, totalActivities)
    const handleConditionalMessages = (thresholdsList) => {
      const messages = generateConditionalMessages(thresholdsList)

      if (messages.length > 0) {
        setShowConditionalPanel(true)
        setConditionalMessage(messages)
      } else {
        setShowConditionalPanel(false)
      }
    }

    handleConditionalMessages(thresholdsList)
  }, [Mtssreport])
  useEffect(() => {
    if (Mtssreport && Mtssreport.activity_breakdown_by_tier) {
      setType((prevTypes) => Mtssreport.activity_breakdown_by_tier.map(
        (_, index) => prevTypes[index] || "student",
      ))
    }
  }, [Mtssreport])

  const { updatedActivityBreakdown, totalActivities } = activityData

  return (
    <div key={activityData}>
      <div className="outer-main-tier-heading">
        <span className="main-tier-heading">
          {commonStrings.activityBreakdown}
        </span>
      </div>

      <Collapse
        expandIcon={({ isActive }) => (
          <UpOutlined className="upOutlined-mtss" rotate={isActive ? 0 : 180} />
        )}
        expandIconPosition="end"
      >
        {updatedActivityBreakdown.map((tier, tierIndex) => {
          const tierActivities = tier.categories.reduce(
            (count, category) => (category.category !== commonStrings.promotedReinforcedIndicators
              ? count + category.items.length
              : count),
            0,
          )

          return (
            <Panel
              header={(
                <div className="main-panel-mtss">
                  <span className="tier-header">
                    {`${commonStrings.tierTitle} ${tierIndex + 1} (${
                      tier.tier
                    })`}
                  </span>
                  <span className="activity-count">
                    {`${tierActivities}/${totalActivities} Activities (${
                      tierPercentages[tierIndex] || 0
                    }%)`}
                  </span>
                </div>
              )}
              key={tierIndex}
              className="custom-panel-mtss"
              style={{
                backgroundColor: CommonConstants.panelColors[tierIndex],
              }}
            >
              <Collapse
                expandIcon={({ isActive, panelKey }) => (
                  <UpOutlined
                    style={{
                      fontSize: "18px",
                      color:
                        nestedPanelColors[
                          parseInt(panelKey.split("-")[1], 10) - 1
                        ] || "#666",
                      cursor: "pointer",
                    }}
                    rotate={isActive ? 0 : 180}
                  />
                )}
                expandIconPosition="end"
              >
                {tier.categories.map((category, categoryIndex) => (
                  <Panel
                    header={(
                      <span
                        style={{
                          color: nestedPanelColors[categoryIndex] || "#666",
                        }}
                        className="panels-mtss"
                      >
                        {category.category}
                      </span>
                    )}
                    key={`${tierIndex}-${categoryIndex}`}
                    className="nested-panel"
                    style={{
                      borderBottom: `1px solid ${
                        nestedPanelColors[categoryIndex] || "#666"
                      }`,
                    }}
                  >
                    <ul
                      className={
                        category.items.some((item) => item.component)
                          ? "activity-mtss-list"
                          : "activity-list"
                      }
                    >
                      {category.items.length > 0 ? (
                        category.items.map((item, itemIndex) => (item.component ? (
                          <div
                            key={item.id || itemIndex}
                            className="mtss-indicators-wrapper"
                          >
                            <MtssIndicators
                              Mtssreport={{
                                activity_breakdown_by_tier: [tier],
                              }}
                              type={type[tierIndex]}
                              setType={(newType) => handleToggleChange(tierIndex, newType)}
                            />
                          </div>
                        ) : (
                          <li key={item.id || itemIndex}>
                            {item.activity} ({item.length_of_time || ""})
                          </li>
                        )))
                      ) : (
                        <li>{commonStrings.noActivityavailabe}</li>
                      )}
                    </ul>
                  </Panel>
                ))}
              </Collapse>
            </Panel>
          )
        })}
      </Collapse>
      {showConditionalPanel && (
        <Collapse
          expandIcon={({ isActive }) => (
            <UpOutlined
              className="upOutlined-mtss"
              rotate={isActive ? 0 : 180}
            />
          )}
          expandIconPosition="end"
        >
          <Panel header="Coaching/Support" className="couching-header">
            <ul className="couching-list">
              {conditionalMessage.map((msg, index) => (
                <li key={index}>{msg}</li>
              ))}
            </ul>
          </Panel>
        </Collapse>
      )}
    </div>
  )
}

export default Mtss
