import React, { useState, useEffect, useLayoutEffect } from "react"
import {
  Row, Col, Divider, Button, Table, Drawer, Alert,
} from "antd"
import _ from "underscore"
import { QuestionCircleOutlined } from "@ant-design/icons"
import commonStrings from "../../commonStrings"
import { uaDarkBlue, uaTeal } from "../../enums"
import BarChart from "../charts/SELQualityBarChart"
import noDataActualVsCapacity from "../../../assets/images/actualVsCapacity.png"

import {
  filterByConnectionType,
  sortObjectsByKey,
  extractKeys,
  extractNames,
  makeCompetencyData,
  filterMaxStudents,
  filterAndRemoveEmptyStandards,
  extractStudentCounts,
  convertObjectIntoArray,
  processDataForDumbbellChart,
  organizeArrayByStandard,
  mergeArraysToObjectKeys,
  removeUniversalTier,
  sortArrayBasedOnToggle,
  makeDessaArray,
  filterByGradeLevel,
  sumStudentsCount,
  filterByCategorySelReport,
} from "../../utilities"
import TimelineDumbbell from "../charts/TimelineDumble"
import componentStyles from "../../styles"

function SELSchoolCapacity({
  type,
  setType,
  id,
  id1,
  id2,
  id3,
  filterSelectedValue,
}) {
  const [activeTab, setActiveTab] = useState(type)

  const [
    currentSchoolActivitiesOnlyStudent,
    setCurrentSchoolActivitiesOnlyStudent,
  ] = useState([])

  useEffect(() => {
    setCurrentSchoolActivitiesOnlyStudent(
      filterByCategorySelReport(window.gon.CURRENT_SCHOOL_ACTIVITIES, "student"),
    )
  }, [window.gon.CURRENT_SCHOOL_ACTIVITIES, type, activeTab])
  const [graphData, setGraphData] = useState([])
  const [currentSchoolActivities, setCurrentSchoolActivities] = useState([])

  const [openModal, setOpenModal] = useState(false)
  const [studentSupportedBySel, setStudentSupportedBySel] = useState({})
  const [supportedMatrixCompetency, setSupportedMatrixCompetency] = useState(
    {},
  )
  const [comparisonActualCapacity, setComparisonActualCapacity] = useState([])
  const [supportedDessaCompetency, setSupportedDessaCompetency] = useState({})

  const [studentsSupportedSelIndicator, setStudentsSupportedSelIndicator] = useState([])

  const [
    studentsSupportedSelIndicatorCount,
    setStudentsSupportedSelIndicatorCount,
  ] = useState([])

  const [eventType, setEventType] = useState("")
  const [selKeys] = useState(makeDessaArray(window.gon.SEL_STANDARDS))

  const onChange = () => {
    setActiveTab(activeTab === "promotes" ? "reinforces" : "promotes")
    setType(activeTab === "promotes" ? "reinforces" : "promotes")
  }

  useEffect(() => {
    setGraphData(
      processDataForDumbbellChart(
        removeUniversalTier(
          filterSelectedValue
            ? filterByGradeLevel(currentSchoolActivities, filterSelectedValue)
            : currentSchoolActivities,
        ),
      ),
    )
  }, [
    currentSchoolActivities,
    currentSchoolActivitiesOnlyStudent,
    filterSelectedValue,
  ])

  useLayoutEffect(() => {
    setCurrentSchoolActivities(
      sumStudentsCount(currentSchoolActivitiesOnlyStudent),
    )
  }, [currentSchoolActivitiesOnlyStudent])

  useEffect(() => {
    setStudentsSupportedSelIndicator(
      sortObjectsByKey(
        filterByConnectionType(
          filterSelectedValue
            ? filterByGradeLevel(
              currentSchoolActivitiesOnlyStudent,
              filterSelectedValue,
            )
            : currentSchoolActivitiesOnlyStudent,
          activeTab === "promotes" ? "reinforces" : "promotes",
        ),
      ),
    )
  }, [activeTab, filterSelectedValue, currentSchoolActivitiesOnlyStudent])

  useEffect(() => {
    if (studentsSupportedSelIndicator.length > 0) {
      const extractedKeys = extractKeys(window.gon.SEL_STANDARDS).map(
        (key) => ({ [key]: 0 }),
      )
      const secondDict = studentsSupportedSelIndicator.reduce((dict, item) => {
        dict[item.key] = item.students_can_supported
        return dict
      }, {})

      const result = extractedKeys.map((item) => {
        const key = Object.keys(item)[0]
        const value = secondDict[key] !== undefined ? secondDict[key] : item[key]
        return { [key]: value }
      })

      setStudentsSupportedSelIndicatorCount(result)
    } else {
      setStudentsSupportedSelIndicatorCount(
        extractKeys(window.gon.SEL_STANDARDS).map((key) => ({ [key]: 0 })),
      )
    }
  }, [studentsSupportedSelIndicator])

  const columnsCommonGraph = [
    {
      title: "Activity Name",
      dataIndex: "activity",
      key: "activity",
      width: 200,
      fixed: "left",
    },
    {
      title: "Indicators",
      dataIndex: "sel_standards",
      key: "sel_standards",
      render: (indicators) => {
        const arrayKeys = Object.keys(indicators)

        return (
          <div className="no-styles">
            {arrayKeys.map((indicator, index) => (
              <div className="tab-student grade-level-boxes" key={indicator}>
                <div className="grade-level-boxes-internal">
                  <span>{indicator}</span>
                </div>
                {arrayKeys.length > 1 && index < arrayKeys.length - 1 && (
                  <div className="div-line" />
                )}
              </div>
            ))}
          </div>
        )
      },
      align: "center",
      width: 100,
    },
    {
      title: "Tier of support",
      dataIndex: "tier_of_support",
      key: "tier_of_support",
      width: 100,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 140,
    },
    {
      title: "Grade Level",
      dataIndex: "internal_selected_grades",
      key: "grade_levels",
      render: (grades) => (
        <div className="no-styles">
          {grades.map((grade, index) => (
            <div className="tab-student grade-level-boxes" key={grade.grade}>
              <div className="grade-level-boxes-internal">
                <span>{grade.grade}</span>
              </div>
              {grades.length > 1 && index < grades.length - 1 && (
                <div className="div-line" />
              )}
            </div>
          ))}
        </div>
      ),
      align: "center",
      width: 100,
    },

    {
      title: "Student(s) can be supported",
      dataIndex: "internal_selected_grades",
      key: "students_can_supported",
      render: (grades) => (
        <div className="no-styles">
          {grades.map((grade, index) => (
            <div className="tab-student grade-level-boxes" key={grade.grade}>
              <div className="grade-level-boxes-internal">
                <span>
                  {grade.students_can_be_supported
                    ? grade.students_can_be_supported
                    : "N/A"}
                </span>
              </div>
              {grades.length > 1 && index < grades.length - 1 && (
                <div className="div-line" />
              )}
            </div>
          ))}
        </div>
      ),
      width: 180,
    },
    {
      title: "Student(s) being supported",
      dataIndex: "internal_selected_grades",
      key: "students_being_supported",
      render: (grades) => (
        <div className="no-styles">
          {grades.map((grade, index) => (
            <div className="tab-student grade-level-boxes" key={grade.grade}>
              <div className="grade-level-boxes-internal">
                <span>
                  {grade.students_being_supported
                    ? grade.students_being_supported
                    : "N/A"}
                </span>
              </div>
              {grades.length > 1 && index < grades.length - 1 && (
                <div className="div-line" />
              )}
            </div>
          ))}
        </div>
      ),
      width: 180,
    },
  ]
  const getDataSource = (data) => {
    const matrixKeys = Object.keys(data)
    const dataSourceKey = matrixKeys.length > 0 ? matrixKeys[0] : null
    return dataSourceKey ? data[dataSourceKey] : []
  }
  const onClose = () => {
    setOpenModal(false)
    setOpenModal(false)
    setStudentSupportedBySel({})
    setSupportedDessaCompetency({})
    setSupportedMatrixCompetency({})
    setComparisonActualCapacity([])
    setEventType("")
  }

  return (
    <Row className="student-capacity mt-4">
      <Divider>
        <Col xs={24} sm={24} lg={24} xl={24}>
          <span className="categories-heading">
            {commonStrings.selSchoolCapacity}
          </span>
        </Col>
      </Divider>

      <Col xs={24} sm={24} md={24} lg={24}>
        <div className="my-2 fontFamily-arc-narrow">
          <Alert
            style={componentStyles.alertStyle}
            message={commonStrings.reportsDataError}
            type="error"
            closable={false}
          />
        </div>
      </Col>

      <Col xs={24} sm={24} lg={24} xl={24}>
        <div className="text-center mt-4">
          <span className="text cyan">Promotes SEL</span>
          <div className="toggle-switch-cap mx-3" onClick={onChange}>
            <div
              className={`slider ${activeTab === "promotes" ? "active" : ""}`}
              style={{
                backgroundImage:
                  activeTab === "promotes"
                    ? "linear-gradient(to bottom, #33485D, white)"
                    : "linear-gradient(to bottom, #49bbbc, white)",
              }}
            />
          </div>
          <span className="text grey">Reinforces SEL</span>
        </div>
      </Col>

      <Col xs={24} sm={24} lg={24} xl={24} className="marginTop70">
        <div id={id} className="col-12">
          <BarChart
            icon={<QuestionCircleOutlined />}
            iconEvent={() => {
              setEventType("question")
              setOpenModal(true)
            }}
            labelX="- SEL Indicators -"
            labelY="- Number Of Students -"
            labels={extractKeys(window.gon.SEL_STANDARDS)}
            datasets={[
              {
                label: "No. of students",
                data: studentsSupportedSelIndicatorCount.map(
                  (obj) => Object.values(obj)[0],
                ),
                backgroundColor:
                  activeTab === "reinforces"
                    ? `rgba(${uaTeal})`
                    : `rgba(${uaDarkBlue})`,
              },
            ]}
            stepSize={1}
            onClick={(e) => {
              setStudentSupportedBySel(
                mergeArraysToObjectKeys(
                  organizeArrayByStandard(
                    sortArrayBasedOnToggle(
                      filterSelectedValue
                        ? filterByGradeLevel(
                          currentSchoolActivitiesOnlyStudent,
                          filterSelectedValue,
                        )
                        : currentSchoolActivitiesOnlyStudent,
                      activeTab === "promotes" ? "reinforces" : "promotes",
                    ),
                  ),
                  extractKeys(window.gon.SEL_STANDARDS),
                )[e],
              )

              setOpenModal(true)
            }}
          />
          <div className="studentsSupportedBySEL">
            <span>{commonStrings.numberStudentSelIndicator}</span>
          </div>
        </div>
      </Col>

      <Col xs={24} sm={24} lg={24} xl={24} className="marginTop70" id={id1}>
        <BarChart
          offset={50}
          labelX="- Matrix Competencies -"
          labelY="- Number Of Students -"
          labels={extractNames(window.gon.SEL_STANDARDS)}
          datasets={[
            {
              label: "No. of students",
              data: extractStudentCounts(
                filterMaxStudents(
                  makeCompetencyData(
                    window.gon.SEL_STANDARDS,
                    filterAndRemoveEmptyStandards(
                      filterSelectedValue
                        ? filterByGradeLevel(
                          currentSchoolActivities,
                          filterSelectedValue,
                        )
                        : currentSchoolActivities,
                      activeTab === "promotes" ? "reinforces" : "promotes",
                    ),
                  ),
                ),
              ),
              backgroundColor:
                activeTab === "reinforces"
                  ? `rgba(${uaTeal})`
                  : `rgba(${uaDarkBlue})`,
            },
          ]}
          stepSize={1}
          onClick={(e) => {
            setSupportedMatrixCompetency(
              convertObjectIntoArray(
                makeCompetencyData(
                  window.gon.SEL_STANDARDS,
                  filterAndRemoveEmptyStandards(
                    filterSelectedValue
                      ? filterByGradeLevel(
                        currentSchoolActivitiesOnlyStudent,
                        filterSelectedValue,
                      )
                      : currentSchoolActivitiesOnlyStudent,
                    activeTab === "promotes" ? "reinforces" : "promotes",
                  ),
                ),
              )[e],
            )
            setOpenModal(true)
          }}
        />
        <div className="studentsSupportedBySEL">
          <span>{commonStrings.numberStudentMatrixCompetency}</span>
        </div>
      </Col>

      <Col xs={24} sm={24} lg={24} xl={24} className="marginTop70" id={id2}>
        <BarChart
          offset={50}
          labelX="- DESSA Competencies -"
          labelY="- Number Of Students -"
          labels={extractNames(window.gon.DESS_SEL_STANDARDS)}
          datasets={[
            {
              label: "No. of students",
              data: extractStudentCounts(
                filterMaxStudents(
                  makeCompetencyData(
                    window.gon.DESS_SEL_STANDARDS,
                    filterAndRemoveEmptyStandards(
                      filterSelectedValue
                        ? filterByGradeLevel(
                          currentSchoolActivitiesOnlyStudent,
                          filterSelectedValue,
                        )
                        : currentSchoolActivitiesOnlyStudent,
                      activeTab === "promotes" ? "reinforces" : "promotes",
                    ),
                  ),
                ),
              ),
              backgroundColor:
                activeTab === "reinforces"
                  ? `rgba(${uaTeal})`
                  : `rgba(${uaDarkBlue})`,
            },
          ]}
          stepSize={1}
          onClick={(e) => {
            setSupportedDessaCompetency(
              convertObjectIntoArray(
                makeCompetencyData(
                  window.gon.DESS_SEL_STANDARDS,
                  filterAndRemoveEmptyStandards(
                    filterSelectedValue
                      ? filterByGradeLevel(
                        currentSchoolActivitiesOnlyStudent,
                        filterSelectedValue,
                      )
                      : currentSchoolActivitiesOnlyStudent,
                    activeTab === "promotes" ? "reinforces" : "promotes",
                  ),
                ),
              )[e],
            )
            setOpenModal(true)
          }}
        />
        <div className="studentsSupportedBySEL">
          <span>{commonStrings.numberStudentDessaCompetency}</span>
        </div>
      </Col>

      <Col xs={24} sm={24} lg={24} xl={24} className="marginTop70" id={id3}>
        <Divider>
          <div className="headingComparisonGraph">
            <span className="text-with-border">Capacity</span>
            <span>{" vs."}</span>
            <span style={{ color: "#40B7B7" }}>{" Actual "}</span>
            <span>{commonStrings.comparisonTargetTertiary}</span>
          </div>
        </Divider>
        {graphData.length > 0 ? (
          <TimelineDumbbell
            graphData={graphData}
            setOpenModal={setOpenModal}
            setComparisonActualCapacity={setComparisonActualCapacity}
          />
        ) : (
          <div className="no-data-actual-vs-capacity">
            <div className="inner-box">
              <div>
                <img
                  className="no-activity-actual-vs-capacity-logo"
                  alt="actual vs capacity"
                  src={noDataActualVsCapacity}
                />
              </div>
              <div>
                <span className="no-activity-actual-vs-capacity">
                  {commonStrings.noActivity}
                </span>
              </div>
            </div>
          </div>
        )}
      </Col>

      <Drawer
        className="schoolSubmissionScores"
        title={(
          <span
            style={{
              fontSize: "30px",
              fontWeight: "600",
              color:
                activeTab === "reinforces"
                  ? `rgba(${uaTeal})`
                  : `rgba(${uaDarkBlue})`,
            }}
          >
            {eventType === "question" ? (
              <div>Definitions</div>
            ) : (
              " Activity Details"
            )}
          </span>
        )}
        placement="top"
        height={eventType === "question" ? "590px" : "87%"}
        closable
        onClose={onClose}
        destroyOnClose
        open={openModal}
        mask
        maskClosable
      >
        <div>
          {eventType === "question"
            ? selKeys.map((value) => (
              <div className="d-flex textSize20px">
                <div>
                  <b>{value.abbreviation}:</b>
                </div>
                  &nbsp;
                <div>
                  <i>{value.explanation}</i>
                </div>
              </div>
            ))
            : (!_.isEmpty(supportedMatrixCompetency)
                || !_.isEmpty(supportedDessaCompetency)
                || !_.isEmpty(studentSupportedBySel)
                || comparisonActualCapacity.length > 0) && (
                <Table
                  className="table-design table-striped-rows drawer-table"
                  columns={columnsCommonGraph}
                  dataSource={
                    comparisonActualCapacity.length > 0
                      ? comparisonActualCapacity
                      : getDataSource(
                        (!_.isEmpty(supportedMatrixCompetency)
                            && supportedMatrixCompetency)
                            || (!_.isEmpty(supportedDessaCompetency)
                              && supportedDessaCompetency)
                            || (!_.isEmpty(studentSupportedBySel)
                              && studentSupportedBySel),
                      )
                  }
                  size="middle"
                  scroll={{ y: 470 }}
                />
            )}
        </div>

        <div className="closeButtonDiv">
          <Button
            className="btnCloseDrawer"
            onClick={() => {
              onClose()
            }}
          >
            {commonStrings.close}
          </Button>
        </div>
      </Drawer>
    </Row>
  )
}

export default SELSchoolCapacity
