import React from "react"
import { Row, Col, Divider } from "antd"
import ReleaseVideoCard from "../ReleaseVidoeCard"

const ReleaseNotes = (props) => {
  const { releaseNotes } = props
  return (
    <Row className="mt-4" style={{ fontFamily: "Archivo Narrow" }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div className="d-flex align-items-center">
          <span className="mx-1 release-notes-chip">Release Notes</span>
        </div>
      </Col>

      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Divider />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <ReleaseVideoCard latestReleaseNotes={releaseNotes} />
      </Col>
    </Row>
  )
}

export default ReleaseNotes
