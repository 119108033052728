import React, { useState, useEffect } from "react"
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  Text,
  Image,
} from "@react-pdf/renderer"
import archivoNarrow from "../../../assets/fonts/ArchivoNarrow-Regular.ttf"
import archivoNarrowBold from "../../../assets/fonts/ArchivoNarrow-Bold.ttf"
import { processTierData } from "../../utilities"
import commonStrings from "../../commonStrings"
import uaLogo from "../../../assets/images/rsp-logo.png"
import pyramidPng from "../../../assets/images/pyramid.png"
import CommonColors from "../../commonColors"

Font.register({
  family: "Archivo Narrow",
  fonts: [{ src: archivoNarrow }, { src: archivoNarrowBold, fontWeight: 700 }],
})

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Archivo Narrow",
    padding: 20,
  },

  tierContainer: {
    border: "1px solid #d9d9d9",
  },
  tierHeader: {
    fontSize: 17,
    fontWeight: "bold",
    color: "white",
    padding: 8,
    //  marginBottom: 10,

    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    alignItems: "center",
  },
  category: {
    marginBottom: 10,
    fontSize: 16,
    padding: 8,
    borderBottomWidth: 1,
    borderBottomColor: CommonColors.UA_WHITE,
    borderBottomStyle: "solid",
  },
  categoryIndicator: {
    fontSize: 16,
    borderBottomWidth: 1,
    borderBottomColor: CommonColors.UA_WHITE,
    borderBottomStyle: "solid",
    padding: 8,

    color: CommonColors.UA_MTSS_PDF_HEADER,
  },

  activity: {
    fontSize: 11,
    marginLeft: 10,
    marginBottom: 5,
    color: CommonColors.UA_MTSS_PDF_ACTIVITY,
  },
  noActivity: {
    fontSize: 11,
    marginLeft: 10,
    marginBottom: 5,
    color: CommonColors.UA_NO_ACTIVITY,
  },
  conditionalMessageContainer: {
    marginTop: 20,

    border: "1px solid #d9d9d9",

    backgroundColor: "white",
  },
  conditionalMessageHeader: {
    marginBottom: 10,
    backgroundColor: CommonColors.UA_RED,
    color: CommonColors.UA_MTSS_HEADER_WHITE,
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: 10,
    padding: 8,
  },
  message: {
    paddingLeft: 10,
    fontSize: 12,
    marginBottom: 10,
    color: CommonColors.UA_MTSS_PDF_ACTIVITY,
    fontWeight: 600,
  },

  imageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  image: {
    width: 700,
    height: 800,
    objectFit: "contain",
  },
  indicatorsSection: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    gap: 2,
  },

  indicatorsColumn: {
    flex: 1,
    backgroundColor: "#ffffff",

    borderLeft: "1px solid #d9d9d9",
  },
  indicatorsTitle: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: 18,
    fontWeight: "bold",
    color: CommonColors.UA_MTSS_PDF_HEADER,
    padding: 10,
    textAlign: "start",
    backgroundColor: CommonColors.UA_MTSS_INDIACTOR,
  },
  indicatorsTitlesection: {
    fontSize: 16,
    paddingLeft: 10,
    display: "flex",
    justifyContent: "flex-start",
    color: CommonColors.UA_MTSS_PDF_HEADER,

    textAlign: "start",
  },
  indicatorsList: {
    fontSize: 12,
    color: CommonColors.UA_MTSS_PDF_ACTIVITY,
    paddingLeft: 10,
    paddingTop: 5,
  },

  sectionList: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  uaLogo: {
    width: 115,
    height: 64,
  },
  uaLogo1: {
    width: 115,
    height: 64,
  },
  mainPyramid: {
    marginVertical: 60,
  },
  mainPyramidText: {
    fontWeight: 700,
    fontSize: 18,
    paddingBottom: 10,
    color: CommonColors.UA_MTSS_PDF_HEADER,
  },
  dataOverviewText: {
    fontWeight: 700,
    fontSize: 18,
    paddingBottom: 10,
    color: CommonColors.UA_MTSS_PDF_HEADER,
  },
  pyramidContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    border: "1px solid #b9b9b9",
  },
  pyramidImage: {
    width: 435,
    height: 390,
    objectFit: "contain",
  },
  pyramidText: {
    position: "absolute",
    fontSize: 14,
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
  },
  pyramidNumberStyle: {
    alignItems: "center",
    paddingHorizontal: 32,
    width: "100%",
  },
  pyramidNumber: {
    fontSize: 18,
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
  },
  pyramidTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
  },
  pyramidTier: {
    fontSize: 13,
    fontWeight: "normal",
    color: "white",
    textAlign: "center",
  },
  noDataMessage: {
    color: CommonColors.UA_MTSS_PDF_HEADER,
    fontSize: 12,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: 5,
  },
  tierActivities: {
    fontSize: 13,
    color: CommonColors.UA_MTSS_TIER_ACTIVITY,
    fontWeight: 500,
    marginLeft: "80",
    flexGrow: 1,
    textAlign: "right",
  },
  tierContainerActivities: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  selIndicator: { fontSize: 12 },
})

const MTSSPdf = ({
  Mtssreport,
  conditionalMessage,
  type,
  currentschoolLogo,
  sortedPyramidData,
  tierPercentages,
  tierActivities,
  totalActivities,
}) => {
  const tiers = (Mtssreport && Mtssreport.activity_breakdown_by_tier) || []

  const tierColors = [
    CommonColors.UA_MTSS_INDIACTOR_BLUE,
    CommonColors.UA_GRAPH_BACKGROUND,
    CommonColors.UA_MTSS_TIER,
  ]
  const nestedPanelColors = [
    CommonColors.UA_RED,
    CommonColors.UA_MTSS_NESTED_RED,
    CommonColors.UA_MTSS_INDIACTOR_BLUE,
    CommonColors.UA_GRAPH_BACKGROUND,
    CommonColors.UA_BOLD_BLACK,
  ]
  const [tierData, setTierData] = useState(
    tiers.length > 0
      ? tiers.map((tier) => processTierData(tier, "student"))
      : [],
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (tiers.length > 0 && type.length > 0) {
        const newTierData = tiers.map((tier, index) => {
          const currentType = type[index] || "student"
          return processTierData(tier, currentType)
        })

        setTierData(newTierData)
      } else {
        setTierData([])
      }
    }, 300)

    return () => clearTimeout(timeout)
  }, [tiers, type])

  const IndicatorList = ({ data }) => {
    if (!data || data.length === 0) {
      return (
        <Text style={pdfStyles.noDataMessage}>{commonStrings.noData}</Text>
      )
    }
    return data.map((section, idx) => (
      <View key={idx} style={{ marginBottom: 10 }}>
        <Text style={pdfStyles.indicatorsTitlesection}>{section.title}</Text>
        {section.keys.map((keyData, i) => (
          <Text key={i} style={pdfStyles.indicatorsList}>
            {keyData.items}
          </Text>
        ))}
      </View>
    ))
  }

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.imageContainer} fixed>
          <Image style={pdfStyles.uaLogo} src={uaLogo} />

          {currentschoolLogo && (
            <Image style={pdfStyles.uaLogo1} src={currentschoolLogo} />
          )}
        </View>
        <View style={pdfStyles.mainPyramid}>
          <Text style={pdfStyles.dataOverviewText}>
            {commonStrings.dataOverview}
          </Text>
          <View style={pdfStyles.pyramidContainer}>
            <Image style={pdfStyles.pyramidImage} src={pyramidPng} />

            {sortedPyramidData.map((tier, index) => (
              <View
                key={index}
                style={{
                  position: "absolute",
                  top: index === 0 ? 75 : index === 1 ? 180 : 299,
                  left: "48%",
                  transform: "translateX(-50%)",
                }}
              >
                <View style={pdfStyles.pyramidNumberStyle}>
                  <Text style={pdfStyles.pyramidNumber}>{tier.count}</Text>
                  <Text style={pdfStyles.pyramidTitle}>
                    {commonStrings.activitiesTitle}
                  </Text>

                  <Text style={pdfStyles.pyramidTier}>
                    {commonStrings.tierTitle} {sortedPyramidData.length - index}{" "}
                    ({tier.tier})
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
      {tiers.map((tier, tierIndex) => {
        const tierItem = tierData[tierIndex] || {}
        const promotionData = tierItem.promotionData || []
        const reinforcementData = tierItem.reinforcementData || []

        const tierType = type[tierIndex] || "student"
        return (
          <Page size="A4" style={pdfStyles.page} key={tierIndex}>
            <View style={pdfStyles.imageContainer} fixed>
              <Image style={pdfStyles.uaLogo} src={uaLogo} />

              {currentschoolLogo && (
                <Image style={pdfStyles.uaLogo1} src={currentschoolLogo} />
              )}
            </View>
            {tierIndex === 0 && (
              <View>
                <Text style={pdfStyles.mainPyramidText}>
                  {commonStrings.activityBreakdown}
                </Text>
              </View>
            )}
            <View key={tierIndex} style={pdfStyles.tierContainer}>
              <View
                style={[
                  pdfStyles.tierHeader,
                  {
                    backgroundColor:
                      tierColors[tierIndex] || CommonColors.UA_RED,
                  },
                ]}
              >
                <Text>
                  {commonStrings.tierTitle} {tierIndex + 1} ({tier.tier})
                </Text>
                <Text style={pdfStyles.tierActivities}>
                  {`${
                    tierActivities[tierIndex]
                  }/${totalActivities} Activities (${
                    tierPercentages[tierIndex] || 0
                  }%)`}
                </Text>
              </View>

              {tier.categories.map((category, categoryIndex) => (
                <View key={categoryIndex}>
                  <Text
                    style={[
                      pdfStyles.category,
                      {
                        color:
                          nestedPanelColors[categoryIndex]
                          || CommonColors.UA_RED,
                        borderBottomWidth: 1,
                        borderBottomColor: CommonColors.UA_WHITE,
                        borderBottomStyle: "solid",
                      },
                    ]}
                  >
                    {category.category}
                  </Text>
                  <View
                    style={[
                      pdfStyles.categoryView,
                      {
                        borderBottomWidth: 1,
                        borderBottomColor:
                          nestedPanelColors[categoryIndex] || "#ffffff",
                        borderBottomStyle: "solid",
                      },
                    ]}
                  >
                    {category.items.length > 0 ? (
                      category.items.map((item, itemIndex) => (
                        <Text style={pdfStyles.activity} key={itemIndex}>
                          • {item.activity} ({item.length_of_time || "N/A"})
                        </Text>
                      ))
                    ) : (
                      <Text style={pdfStyles.activity}>
                        {commonStrings.noActivityavailabe}
                      </Text>
                    )}
                  </View>
                </View>
              ))}

              {/* SEL Indicators being promoted and Reinforced Category */}
              {promotionData && reinforcementData && (
                <View>
                  <Text style={pdfStyles.categoryIndicator}>
                    {commonStrings.promotedReinforcedIndicators}
                    <Text style={pdfStyles.selIndicator}>
                      {tierType === "adult"
                        ? " (Adult SEL)"
                        : tierType === "student"
                          ? " (Student SEL )"
                          : ""}
                    </Text>
                  </Text>
                  <View style={pdfStyles.categoryView}>
                    {/* SEL Indicators Section */}
                    <View style={pdfStyles.indicatorsSection}>
                      <View style={pdfStyles.indicatorsColumn}>
                        <Text style={pdfStyles.indicatorsTitle}>
                          {commonStrings.promotionText}
                        </Text>
                        <IndicatorList data={promotionData || []} />
                      </View>
                      <View style={pdfStyles.indicatorsColumn}>
                        <Text style={pdfStyles.indicatorsTitle}>
                          {commonStrings.reinforcementText}
                        </Text>
                        <IndicatorList data={reinforcementData || []} />
                      </View>
                    </View>
                  </View>
                </View>
              )}
            </View>
          </Page>
        )
      })}
      {conditionalMessage && conditionalMessage.length > 0 && (
        <Page size="A4" style={pdfStyles.page}>
          <View style={pdfStyles.imageContainer} fixed>
            <Image style={pdfStyles.uaLogo} src={uaLogo} />

            {currentschoolLogo && (
              <Image style={pdfStyles.uaLogo1} src={currentschoolLogo} />
            )}
          </View>
          <View style={pdfStyles.conditionalMessageContainer}>
            <Text style={pdfStyles.conditionalMessageHeader}>
              {commonStrings.couchingTitle}
            </Text>
            <View style={pdfStyles.conditionalMessageContent}>
              {conditionalMessage.map((msg, index) => (
                <Text key={index} style={pdfStyles.message}>
                  • {msg}
                </Text>
              ))}
            </View>
          </View>
        </Page>
      )}
    </Document>
  )
}

export default MTSSPdf
