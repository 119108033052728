import activeIconTemplate from "../assets/images/activeIconTemplate.png"
import activeIconGuidance from "../assets/images/activeIconGuidance.png"
import activeIconGuides from "../assets/images/activeIconGuides.png"
import activeIconOther from "../assets/images/activeIconOther.png"
import activeIconProfessionalDevelopment from "../assets/images/activeIconprofessionaldevelopment.png"
import activeIconRelease from "../assets/images/activeIconRelease.png"
import activeIconRubric from "../assets/images/activeIconRubric.png"
import activeIconActivities from "../assets/images/activeIconActivities.png"

import defaultGuidance from "../assets/images/defaultguidance.png"
import defaultGuides from "../assets/images/defaultguides.png"
import defaultOther from "../assets/images/defaultother.png"
import defaultProfessionalDevelopment from "../assets/images/defaultprofessionaldevelopment.png"
import defaultReleases from "../assets/images/defaultreleases.png"
import defaultRubrics from "../assets/images/defaultrubrics.png"
import defaultTemplate from "../assets/images/defaulttemplate.png"
import defaultActivities from "../assets/images/defaultactivities.png"

import surveyDefault from "../assets/images/surveyadefault.png"
import surveyActive from "../assets/images/surveyactive.png"
import visualDefault from "../assets/images/visualdefault.png"
import visualActive from "../assets/images/visualactive.png"
import allDefault from "../assets/images/alldefault.png"
import allActive from "../assets/images/allactive.png"

const IconConstants = {
  All: {
    active: allActive,
    default: allDefault,
  },
  Survey: {
    active: surveyActive,
    default: surveyDefault,
  },
  "Poster/Visual": {
    active: visualActive,
    default: visualDefault,
  },
  Rubric: {
    active: activeIconRubric,
    default: defaultRubrics,
  },
  Template: {
    active: activeIconTemplate,
    default: defaultTemplate,
  },
  Guide: {
    active: activeIconGuides,
    default: defaultGuides,
  },
  Activity: {
    active: activeIconActivities,
    default: defaultActivities,
  },
  "Professional Development": {
    active: activeIconProfessionalDevelopment,
    default: defaultProfessionalDevelopment,
  },
  Guidance: {
    active: activeIconGuidance,
    default: defaultGuidance,
  },
  Other: {
    active: activeIconOther,
    default: defaultOther,
  },
  Release: {
    active: activeIconRelease,
    default: defaultReleases,
  },
}
export default IconConstants
