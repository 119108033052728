import React, { useEffect, useState } from "react"
import { Button, Modal } from "antd"
import ReactPlayer from "react-player"
import Closecircle from "../../assets/images/Closecircle.png"
import tourimage from "../../assets/images/tourimage.png"
import commonStrings from "../commonStrings"
import { getCurrentVideoSource, formatStepCounter } from "../utilities"

const Tour = ({
  title,
  description,
  currentStep,
  totalSteps,
  handleNext,
  handleSkip,
  handleBack,
}) => {
  const [videoUrls, setVideoUrls] = useState([])
  const [playVideo, setPlayVideo] = useState(true)
  const [isReplayVisible, setIsReplayVisible] = useState(false)

  useEffect(() => {
    APIClient.get("/s3_files", (response) => {
      if (response.status === "200") {
        const sortedUrls = response.data.sort((a, b) => {
          const fileNameA = a.split("/").pop().toLowerCase()
          const fileNameB = b.split("/").pop().toLowerCase()

          if (fileNameA < fileNameB) return -1
          if (fileNameA > fileNameB) return 1
          return 0
        })

        setVideoUrls(sortedUrls)
      }
    })
  }, [])
  useEffect(() => {
    setIsReplayVisible(false)
    setPlayVideo(true)
  }, [currentStep])
  const handleReplay = () => {
    setPlayVideo(false)
    setTimeout(() => {
      setPlayVideo(true)
      setIsReplayVisible(false)
    }, 0)
  }

  const handleVideoPlay = () => {
    setIsReplayVisible(false)
  }

  const handleVideoEnd = () => {
    setIsReplayVisible(true)
  }

  return (
    <div>
      <Modal
        keyboard
        title={currentStep === 0 ? title : null}
        visible
        className={`modal-container tour-modal ${
          currentStep !== 0 ? "step-not-zero" : "step-zero"
        }`}
        onCancel={handleSkip}
        footer={null}
        closeIcon={(
          <div className="custom-close-icon">
            <img
              className="close-tour-icon"
              src={Closecircle}
              alt="Close Icon"
            />
          </div>
        )}
      >
        <div className="tutorial-content">
          <div className="video-container-tour">
            {currentStep === 0 ? (
              <img
                src={tourimage}
                alt="Tour Step"
                className="tour-image"
                style={{ width: "850px", height: "510px" }}
              />
            ) : (
              <div>
                <ReactPlayer
                  url={getCurrentVideoSource(videoUrls, currentStep)}
                  playing={playVideo}
                  controls={false}
                  className="videos-section-tour"
                  onPlay={handleVideoPlay}
                  onEnded={handleVideoEnd}
                />
              </div>
            )}
          </div>

          <div className="main-tour-header">
            <div className="inner-main-tour-header">
              <div className="tour-description">
                <span>{description}</span>
              </div>
              {isReplayVisible && (
                <div className="button-tour-replay">
                  <Button
                    onClick={handleReplay}
                    type="primary"
                    className="replay-video-button"
                  >
                    {commonStrings.replayTutorial}
                  </Button>
                </div>
              )}
            </div>
          </div>

          <div className="tour-footer">
            <div className="tour-step-counter">
              <span>{formatStepCounter(currentStep, totalSteps)}</span>
            </div>
            <div className="tour-buttons">
              {currentStep + 1 !== totalSteps && (
                <Button
                  className="skipbutton-tour"
                  key="skip"
                  onClick={handleSkip}
                >
                  {commonStrings.skip}
                </Button>
              )}
              {currentStep > 0 && (
                <Button
                  className="backbutton-tour"
                  key="back"
                  type="primary"
                  onClick={handleBack}
                >
                  {commonStrings.back}
                </Button>
              )}
              {currentStep + 1 === totalSteps ? (
                <Button
                  className="finishbutton-tour"
                  key="finish"
                  type="primary"
                  onClick={handleSkip}
                >
                  {commonStrings.finish}
                </Button>
              ) : (
                <Button
                  className="nextbutton-tour"
                  key="next"
                  type="primary"
                  onClick={handleNext}
                >
                  {commonStrings.next}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Tour
