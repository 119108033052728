import React, { useState, useCallback, useEffect } from "react"
import { useDebounceEffect } from "ahooks"
import { Form, notification } from "antd"
import _ from "underscore"
import ProgramMatrixHeader from "./Header"
import ProgramMatrixTabs from "./Tabs"
import ProgramMatrixButtons from "./Buttons"
import ProgramMatrixSecondForm from "./ProgramMatrixSecondForm"
import { areYouSure, errorAlertforProgramMatrix } from "../../utilities"
import commonStrings from "../../commonStrings"
import Loader from "../CommonComponents/Loader"

// import generateChatResponse from "../chatgpt/gpt"

function ProgramMatrix({ matrix, programAssociatedSchools, currentSchool }) {
  const [item, setItem] = useState({})

  const [routeURL, setRouteURL] = useState()
  const urlParams = new URLSearchParams(window.location.search)
  const [myParam] = useState(urlParams.get("activityId"))
  const [activityMode] = useState(urlParams.get("mode"))
  const [completedActivities, setCompletedActivities] = useState()
  const {
    workflow_state: workflowState,
    student_population: studentPopulation,
  } = matrix

  const [matrixItems, setMatrixItems] = useState(matrix.items)
  const [category, setCategory] = useState(routeURL)
  const [step, setStep] = useState(1)
  const [indicatorArray] = useState([1])
  const [isShowIndicator] = useState(true)
  const [initialItem, setInitialItem] = useState()
  const [form] = Form.useForm()
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const [indicator, setIndicator] = useState("")
  const [fieldsValues, setFieldsValues] = useState({})
  const [isButtonSubmitted, setIsButtonSubmitted] = useState(false)

  const initialGradeLevels = (Array.isArray(item.internal_selected_grades)
    ? item.internal_selected_grades.length > 0
    : false) || item.is_user_edited
    ? (item.internal_selected_grades || []).map((g) => g.grade)
    : (matrix.selected_grades || []).map((g) => g.grade)

  const [selectedGradeLevels, setSelectedGradeLevels] = useState(initialGradeLevels)
  console.log("fieldsValues", fieldsValues)
  // const [gptResponse, setGptResponse] = useState(null)

  useEffect(() => {
    localStorage.setItem("currentURL", window.location.href)
  }, [])

  useEffect(() => {
    if (myParam) {
      APIClient.get(
        `/program_matrix/program_matrix_items/${myParam}`,
        (response) => {
          // set function of matrix

          setItem(response)
          localStorage.removeItem("description")
          setInitialItem(response)
          if (response.category) {
            const currentUrl = window.location.href
            const replacedUrl = currentUrl.replace(
              /\?(.*?)&/,
              `?${response.category}&`,
            )
            window.history.pushState({ path: replacedUrl }, "", replacedUrl)
          }
        },
      )
    }
  }, [myParam])
  useEffect(() => {
    if (item && item.category) {
      const currentUrl = window.location.href
      const replacedUrl = currentUrl.replace(/\?(.*?)&/, `?${item.category}&`)
      window.history.pushState({ path: replacedUrl }, "", replacedUrl)
    }
  }, [item])
  const createItem = (category) => {
    APIClient.post(
      "/program_matrix/program_matrix_items/",
      { category },
      (response) => {
        setCategory(response.category)
        window.location.href = `/program_matrix/new?${response.category}&activityId=${response.id}&mode=add`
      },
    )
  }

  function findObjectById(object, array) {
    const resultObject = array.find((item) => item.id === object.id)
    if (resultObject === undefined) {
      window.location.href = "/"
    }
  }

  useEffect(() => {
    findObjectById(currentSchool, programAssociatedSchools)
  }, [currentSchool, programAssociatedSchools])
  const [grades, setGrades] = useState([])

  const handleGradesUpdate = (updatedGrades) => {
    setGrades(updatedGrades)
    setFieldsValues((prevValues) => ({
      ...prevValues,
      internal_selected_grades: updatedGrades,
      is_user_edited: true,
    }))

    // set value of matrix on chnage

    setItem((prevItem) => ({
      ...prevItem,
      internal_selected_grades: updatedGrades,
      is_user_edited: true,
    }))
  }

  useEffect(() => {
    if (fieldsValues.internal_selected_grades) {
      setGrades(fieldsValues.internal_selected_grades)
    }
  }, [fieldsValues.internal_selected_grades])
  const handleGradeLevelsChange = (values) => {
    const updatedGradeLevels = Array.isArray(values) ? values : []

    const updatedInternalGrades = updatedGradeLevels.map((grade) => ({
      grade,
      students_can_be_supported: null,
      students_being_supported: null,
    }))

    setSelectedGradeLevels(updatedGradeLevels)

    setFieldsValues((prevValues) => ({
      ...prevValues,
      internal_selected_grades: updatedInternalGrades,
    }))

    setItem((prevValues) => ({
      ...prevValues,
      internal_selected_grades: updatedInternalGrades,
      is_user_edited: true,
    }))
  }

  const handleCompleted = (newItem) => {
    if (
      newItem
      && newItem.activity
      && newItem.tier_of_support
      && newItem.length_of_time
      && newItem.description
      && newItem.data_collection
      && !_.isEmpty(newItem.internal_selected_grades)
      && !_.isEmpty(newItem.sel_standards)
    ) {
      const tempArr = []
      Object.values(newItem.sel_standards).map((element) => {
        const status = element.connection_explanation
          ? tempArr.push(true)
          : tempArr.push(false)
        return status
      })
      const value = tempArr.includes(false)
        ? (newItem.is_completed = false)
        : (newItem.is_completed = true)
      return value
    }

    newItem.is_completed = false
    return null
  }

  const handleOnChange = (e) => {
    const newItem = { ...item }

    switch (e.target.name) {
      case "sel_standards":
        if (_.contains(_.keys(newItem.sel_standards), e.target.value)) {
          newItem.sel_standards = _.omit(newItem.sel_standards, e.target.value)
          setIndicator("")
        } else {
          newItem.sel_standards[e.target.value] = {
            connection_type: "promotes",
          }
          setIndicator("")
        }
        break
      case "connection_type":
        newItem.sel_standards[e.target.ast][e.target.name] = e.target.value
        break
      case "connection_explanation":
        newItem.sel_standards[e.target.getAttribute("data-sel-standard-key")][
          e.target.name
        ] = e.target.value
        break
      case "activity": {
        const activityValue = e.target.value.trim()

        if (item.activity !== activityValue) {
          newItem[e.target.name] = activityValue
          newItem.sel_standards = {}
          newItem.is_completed = false
          setIndicator("")

          const urlParams = new URLSearchParams(window.location.search)
          const tagNameParam = urlParams.get("tagName")

          if (!tagNameParam || !tagNameParam.includes(activityValue)) {
            urlParams.set("tagName", activityValue)
            const newUrl = `${
              window.location.pathname
            }?${urlParams.toString()}`
            window.history.pushState({}, "", newUrl)
          }
        }
        break
      }
      case "internal_selected_grades":
        newItem.internal_selected_grades = newItem.internal_selected_grades || []
        if (newItem.internal_selected_grades.includes(val.value)) {
          newItem.internal_selected_grades = newItem.internal_selected_grades.filter(
            (level) => level !== val.value,
          )
        } else {
          newItem.internal_selected_grades = [
            ...newItem.internal_selected_grades,
            val.value,
          ]
        }
        break

      default:
        newItem[e.target.name] = e.target.value
    }

    handleCompleted(newItem)

    setItem(newItem)
  }

  const removeItem = (value) => {
    setMatrixItems(matrixItems.filter((i) => i.id !== value.id))
  }

  function getActivityIdFromUrl(url) {
    const urlParams = new URLSearchParams(url)
    const activityId = urlParams.get("activityId")

    return activityId
  }

  useEffect(() => {
    handleCompleted(item)
  }, [item])

  const deleteItem = () => {
    areYouSure("warning", () => {
      APIClient.delete(
        `/program_matrix/program_matrix_items/${item.id}`,
        () => {
          removeItem(item)
        },
      )
    })
  }

  const updateStudentsCount = (endpoint, count) => {
    const activityId = getActivityIdFromUrl(window.location.href)
    APIClient.put(
      `/program_matrix/program_matrix_items/${activityId}/${endpoint}`,
      { count },
      () => {
        hideSaving()
      },
      (errors) => {
        console.log(errors)
      },
    )
  }

  const deleteIndicator = (key) => {
    const updatedItem = { ...item, sel_standards: {}, is_completed: false }

    if (_.isEmpty(item.sel_standards)) {
      const updatedItem = { ...item }
      updatedItem.category = key
      updatedItem.is_completed = false
      APIClient.put(
        `/program_matrix/program_matrix_items/${item.id}`,
        updatedItem,
        () => {
          removeItem(updatedItem)

          setItem(updatedItem)
        },
      )
    } else {
      areYouSure(
        "warning",
        () => {
          if (key) {
            updatedItem.category = key
            updatedItem.adult = updatedItem.category === "Adult SEL"
          }
          updatedItem.sel_standards = {}
          updatedItem.is_completed = false
          APIClient.put(
            `/program_matrix/program_matrix_items/${item.id}`,
            updatedItem,
            () => {
              removeItem(updatedItem)

              setItem(updatedItem)
              notification.success({
                message: "Indicator Deleted",
                description: commonStrings.indicatorChangemessage,
                placement: "topRight",
                duration: 1,
                onClose: () => {
                  window.location.href = "/program_matrices"
                },
              })
            },
          )
        },
        () => {
          if (item) {
            const updatedItem = { ...item }

            setItem(updatedItem)
          }
          window.location.reload()
        },
        commonStrings.categoryChangeMessage,
      )
    }
  }

  const confirmSubmit = () => {
    setIsSubmitClicked(!isSubmitClicked)
    fetch(
      `/api/v1/program_matrix/program_matrix_items/completed_activities_count?category=${category}`,
    )
      .then((response) => response.json())
      .then((json) => {
        const count = json.completed_activities
        const text = count < 5
          ? `You have almost met your goal for ${routeURL}! Please add at least ${
            5 - count
          } more activities.`
          : `You have completed your goal for ${routeURL}!`
        handleCompleted(item)
        areYouSure(
          "success",
          () => {
            setTimeout(() => {
              createItem(category)
            }, 1000)
          },
          () => {
            setTimeout(() => {
              window.location.href = "/program_matrices"
            }, 1000)
          },
          "Great Job!",
          text,
          "Add Activity",
          "No, I'm Done",
        )
      })
  }

  const submitClicked = () => {
    let isSelIndicatorCompleted = false
    let errors = []
    fetch(
      `/api/v1/program_matrix/program_matrix_items/${myParam}/sel_indicator_validation/`,
    )
      .then((response) => response.json())
      .then((json) => {
        errors = json.incomplete_indicators
        if (!_.isEmpty(json.incomplete_indicators)) {
          isSelIndicatorCompleted = true
        }
        form.validateFields().then(() => (_.isEmpty(item.sel_standards)
          ? errorAlertforProgramMatrix({
            text: "Please add at least one SEL Indicator",
          })
          : isSelIndicatorCompleted
            ? errorAlertforProgramMatrix({
              html: `Please complete SEL Indicators: ${errors.join(", ")}`,
            })
            : confirmSubmit()))
      })
  }

  const removeStandard = (key) => {
    areYouSure("warning", () => {
      handleOnChange({ target: { name: "sel_standards", value: key } })
    })
  }

  const saveItem = (key) => {
    // if (!_.isEmpty(key)) {
    // deleteIndicator(key);
    // } else if (item && item !== initialItem && item.id) {
    // showSaving();
    if (!_.isEmpty(key)) {
      deleteIndicator(key)
      // window.location.href = "/program_matrices";
    } else if (item && item !== initialItem && item.id) {
      showSaving()

      APIClient.put(
        `/program_matrix/program_matrix_items/${item.id}`,
        {
          // activity: item.activity,
          activity: urlParams.get("tagName")
            ? urlParams.get("tagName")
            : item.activity,
          tier_of_support: item.tier_of_support,
          length_of_time: item.length_of_time,
          // grade_levels: item.grade_levels,
          description: item.description,
          data_collection: item.data_collection,
          sel_standards: item.sel_standards,
          is_completed: item.is_completed || false,
          category: key,
          internal_selected_grades: item.internal_selected_grades,
          is_user_edited: item.is_user_edited,
        },
        () => {
          hideSaving()
          const currentUrl = window.location.href
          const replacedUrl = currentUrl.replace(/\?(.*?)&/, `?${key}&`)
          window.location.href = replacedUrl
        },
      )
    }
  }

  useDebounceEffect(
    () => {
      if (item && item !== initialItem && item.id) {
        showSaving()

        APIClient.put(
          `/program_matrix/program_matrix_items/${item.id}`,
          {
            activity: urlParams.get("tagName")
              ? urlParams.get("tagName")
              : item.activity,
            tier_of_support: item.tier_of_support,
            length_of_time: item.length_of_time,
            description: item.description,
            data_collection: item.data_collection,
            sel_standards: item.sel_standards,
            is_completed: item.is_completed || false,
            category: item.category,
            internal_selected_grades: item.internal_selected_grades,
            is_user_edited:
              (item.internal_selected_grades.length > 0 && true)
              || item.is_user_edited,
          },
          () => {
            hideSaving()
          },
        )
      }
    },
    [item, urlParams.get("tagName")],
    { wait: 250 },
  )

  useEffect(() => {
    $("[data-toggle=\"tooltip\"]").tooltip()
  })
  const handleOnChangeSelect = (e, val) => {
    const newItem = { ...item }
    switch (val.name) {
      case "sel_standards":
        if (_.contains(_.keys(newItem.sel_standards), val.value)) {
          newItem.sel_standards = _.omit(newItem.sel_standards, val.value)
        } else {
          newItem.sel_standards[val.value] = {
            connection_type: item.adult ? "promotes" : "",
          }
        }
        break
      case "connection_type":
      case "connection_explanation":
        newItem.sel_standards[val.name] = val.value
        break
      case "categories":
        if (_.contains(_.keys(newItem.category), val.value)) {
          newItem.category = _.omit(newItem.category, val.value)
        }
        newItem[val.name] = val.value
        break

      default:
        newItem[val.name] = val.value
    }

    handleCompleted(newItem)

    setItem(newItem)
  }

  useEffect(() => {
    const newURL = window.location.href.split("?")[1]
    const activityName = newURL.split("&")[0]
    const name = activityName.replace(/%20/g, " ")
    setRouteURL(name)
    APIClient.get(
      `/program_matrix/program_matrix_items/completed_activities_count?category=${name}`,
      (response) => {
        setCompletedActivities(response.completed_activities)
      },
    )
  }, [window.location.href, isSubmitClicked])

  const handleNextStep = useCallback(() => {
    // setData(dataNo)
    if (step < 2) {
      setStep(step + 1)
    }
  }, [step])

  const handlePrevStep = useCallback(() => {
    // setData(data)
    if (step > 1) {
      setStep(step - 1)
    }
  }, [step])

  const addItem = (value) => {
    setMatrixItems([...matrixItems, value])
  }

  const extractTagNameFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const activityFromUrl = urlParams.get("tagName")
    if (activityFromUrl) {
      setItem((prevItem) => ({
        ...prevItem,
        activity: activityFromUrl,
      }))
    }
  }

  useEffect(() => {
    extractTagNameFromURL()
  }, [window.location.search])

  useEffect(() => {
    if (myParam) {
      APIClient.get(`/program_matrix/program_matrix_items/${myParam}`, () => {
        extractTagNameFromURL()
      })
    }
  }, [myParam])

  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false)

  const handleGenerateResponse = () => {
    if (!isGeneratingResponse) {
      setIsGeneratingResponse(true)
      const tagNameFromUrl = urlParams.get("tagName")
      const activityToUse = tagNameFromUrl || item.activity

      if (myParam) {
        APIClient.get(
          `/program_matrix/program_matrix_items/${myParam}`,
          (response) => {
            if (response && response.activity) {
              generateGptResponse(activityToUse, 2)
            } else {
              console.error("Activity is null or undefined")
            }
            setIsGeneratingResponse(false)
          },
          (error) => {
            console.error("Error fetching program matrix item:", error)
            setIsGeneratingResponse(false)
          },
        )
      } else {
        console.error("Activity ID (myParam) is null or undefined")
        setIsGeneratingResponse(false)
      }
    }
  }

  const [loading, setLoading] = useState(false)
  return (
    <div className="rubric program-matrix ">
      <ProgramMatrixHeader
        completedActivities={completedActivities}
        fieldsValues={fieldsValues}
        matrix={matrix}
        item={item}
      />
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="container">
          {step === 1 && (
            <ProgramMatrixTabs
              setCategory={setCategory}
              category={category}
              showReport={workflowState === "submitted"}
              routeURL={routeURL}
              handleOnChange={handleOnChange}
              handleOnChangeSelect={handleOnChangeSelect}
              form={form}
              item={item}
              createItem={createItem}
              activityMode={activityMode}
              saveItem={saveItem}
              studentPopulation={studentPopulation}
              updateStudentsCount={updateStudentsCount}
              getActivityIdFromUrl={getActivityIdFromUrl}
              setFieldsValues={setFieldsValues}
              fieldsValues={fieldsValues}
              isButtonSubmitted={isButtonSubmitted}
              setIsButtonSubmitted={setIsButtonSubmitted}
              handleGenerateResponse={handleGenerateResponse}
              initialItem={initialItem}
              handleGradeLevelsChange={handleGradeLevelsChange}
              selectedGradeLevels={selectedGradeLevels}
              setSelectedGradeLevels={setSelectedGradeLevels}
              matrix={matrix}
              onGradesUpdate={handleGradesUpdate}
              grades={grades}
              setItem={setItem}
              setLoading={setLoading}
              // internalSelectedGrades={internalSelectedGrades}
            />
          )}

          {step === 2 && (
            <ProgramMatrixSecondForm
              setCategory={setCategory}
              category={category}
              showReport={workflowState === "submitted"}
              indicatorArray={indicatorArray}
              handleOnChange={handleOnChange}
              handleOnChangeSelect={handleOnChangeSelect}
              form={form}
              item={item}
              deleteItem={deleteItem}
              removeStandard={removeStandard}
              indicator={indicator}
              setIndicator={setIndicator}
            />
          )}
          <ProgramMatrixButtons
            canAdd={workflowState === "draft"}
            Next
            addItem={addItem}
            category={category}
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
            step={step}
            isShowIndicator={isShowIndicator}
            form={form}
            indicatorArray={indicatorArray}
            submitClicked={submitClicked}
            fieldsValues={fieldsValues}
            setIsButtonSubmitted={setIsButtonSubmitted}
            setFieldsValues={setFieldsValues}
          />
        </div>
      )}
    </div>
  )
}

export default ProgramMatrix
